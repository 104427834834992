import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

export interface IPageNavbarButton {
  label: string;
  isActive: boolean;
  component: any;
  icon: string;
}

export interface IButtonWithPlace {
  place: string;
  buttons: IPageNavbarButton[];
}
export interface ITabs {
  name: string;
  icon: string;
  component: any;
}
@Component({
  selector: "app-page-navbar",
  templateUrl: "./page-navbar.component.html",
  styleUrls: ["./page-navbar.component.scss"],
})
export class PageNavbarComponent implements OnInit {
  @Input() buttons: IButtonWithPlace[] = [];
  @Output() componentEvent = new EventEmitter<any>();
  @Input() isVertical: boolean = false;

  ngOnInit(): void {
    this.buttons.forEach((b) => {
      b.buttons.forEach((ib) => {
        if (ib.isActive) {
          this.componentEvent.emit(ib.component);
          return;
        }
      });
    });
  }

  toggleLayout() {
    this.isVertical = !this.isVertical;
  }

  onClick(placeWithBtnInd: number, index: number, place: string) {
    this.buttons.forEach((b, i) => {
      if (b.place === place) {
        b.buttons.forEach((ib, ii) => {
          ii !== index ? (ib.isActive = false) : (ib.isActive = true);
        });
        this.buttons[i].buttons[index].isActive = true;
      }
    });

    /** Emit the component to show **/
    this.componentEvent.emit(
      this.buttons[placeWithBtnInd].buttons[index].component
    );
  }
}

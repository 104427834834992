<div class="text-center top">
  <h3 class="mt-3">{{ pageName }} will be added soon</h3>
  <lord-icon
    src="https://cdn.lordicon.com/ssfrfbhy.json"
    trigger="loop"
    stroke="light"
    colors="primary:#121331,secondary:#ee6d66"
    style="width: 150px; height: 150px"
  >
  </lord-icon>
</div>

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-round-preloader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './round-preloader.component.html',
  styleUrl: './round-preloader.component.scss'
})
export class RoundPreloaderComponent {

}

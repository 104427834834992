import { Component, Input } from "@angular/core";
@Component({
  selector: "app-preloader",
  templateUrl: "./preloader.component.html",
  styleUrls: ["./preloader.component.scss"],
})
export class PreloaderComponent {
  @Input() column: number = 5;
  @Input() row: number = 5;
  ngOnIt() {}
  getCellArray(): number[] {
    return Array.from({ length: this.column }, (_, index) => index + 1);
  }
  getRowArray(): number[] {
    return Array.from({ length: this.row }, (_, index) => index + 1);
  }
}

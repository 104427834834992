import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  CUSTOM_ELEMENTS_SCHEMA,
} from "@angular/core";
import { NavigationEnd, Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { MENU } from "./menu";
import { MenuItem } from "./menu.model";
// import { environment } from "src/environments/environment";
import { SharedService } from "src/app/core/services/shared-service.service";
import { backendService } from "src/app/core/services/backendService.service";
import { AuthService } from "src/app/core/services/authentication.service";
import { HotToastService } from "@ngxpert/hot-toast";
import {
  IComponent,
  INanoComponents,
  ISemicomponents,
} from "src/app/core/utils/Icomponents";
import { IsideMenu } from "src/app/core/utils/sidemenu.interface";
import { throwServerError } from "@apollo/client/core";
type homeMenus = {
  name: string;
  path: string;
  icon: string;
  isActive: boolean;
  id: string;
};
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  homeMenus: homeMenus[] = [
    {
      name: "Home",
      path: "/",
      icon: "ri-home-3-line",
      isActive: false,
      id: "1",
    },
    {
      name: "Organization",
      path: "/organization",
      icon: "ri-team-line",
      isActive: false,
      id: "2",
    },
    {
      name: "Attendance",
      path: "/attendance",
      icon: "bx bxs-calendar-check",
      isActive: false,
      id: "3",
    },
    {
      name: "Finance",
      path: "/financial",
      icon: " ri-wallet-3-line",
      isActive: false,
      id: "4",
    },
    {
      name: "Broadcast",
      path: "/broadcast",
      icon: "ri-base-station-line",
      isActive: false,
      id: "5",
    },
    {
      name: "Profile",
      path: "/profile",
      icon: " ri-account-pin-box-line",
      isActive: false,
      id: "6",
    },
  ];
  refreshed: boolean = true;
  updateSidebar: boolean = false; // will be false when showing the home sidebar and true when showing the dynamic sidebar
  moduleLink!: string;
  components: IComponent[] = [];
  selectedComponent!: IComponent | null;
  semicomponents: ISemicomponents[] = [];

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private backendService: backendService,
    private authService: AuthService,
    private toast: HotToastService,
    private route: ActivatedRoute
  ) {
    /**PROCESS
     * When the page is refreshed, the top nav bar fetches modules that a person has.
     * This modules are used to show whether a person can do something on the system or not.
     * To tackle people who want to paste link of certain pages and try to access it, we need to check
     * the person's modules to make sure the person can access the page.
     *
     * On this page, it will decide to whether show components or default home menus.
     * We will first check the routes that the person is on.
     * When the person has multiple route parts, we will compare the first part of the route the module links
     * to see if the person has access to that module.
     * If the person has access to the module, we will show that module's components on the sidebar,
     * else we will show the home menus and route the  person to the home page.
     */

    //Check Routes to see all routes
    let moduleLink = this.router.url.split("/")[1];
    this.sharedService.selectedModule$.subscribe((res) => {
      this.refreshed = true; // for referesh whenever a module is selected
    });
    if (moduleLink != "") {
      //cross-check with the modules that the person has to confirm if the person has access to that module
      this.sharedService.modules$.subscribe((modules) => {
        if (modules.length > 0) {
          let module = modules.find((module) =>
            module.moduleLink.includes(moduleLink)
          );
          //if module exists then it means the person has access to the module, we will show the components of that module
          if (module) {
            this.getComponents(module.moduleId);
          } else {
            //if the person does not have access to the module, we will show the home menus and route the person to the home page
            this.router.navigate(["/"]);
            this.refreshed = false;
            this.updateSidebar = false;
            //and we will show static menu e70d4bb1-8283-49c9-a295-b36b5b1f8e76 ae0af19c-df23-4f9f-ba35-5df607951d03
          }
        }
      });
    }
    //if the person clicks on a different module, we will show the components of that module
    this.sharedService.sideMenu$.subscribe((menu) => {
      if (menu.components) {
        //also when showStatic is true it means that it is routing to home thus we need to set home menu to active.
        if (menu.showStaticSideMenu) {
          this.sharedService.updateTriggerForPageLoad(true); // trigger the page to laod only when the page is loaded
          if (this.homeMenus) {
            this.homeMenus.forEach((menu) => {
              if (menu.path === this.router.url) {
                menu.isActive = true;
              } else {
                menu.isActive = false;
              }
            });
          }
        }
        this.refreshed = false; //remove loader
        if (menu.showStaticSideMenu) {
          this.updateSidebar = false;
        } else {
          this.updateSidebar = true;
          this.components = menu.components;
          this.moduleLink = this.router.url.split("/")[1];
          this.components.forEach((component) => {
            component.isCollapsed = true;
            this.sharedService.updateTriggerForPageLoad(true); // trigger the page to laod only when the page is loaded
            if (component.componentLink == this.router.url.split("/")[2]) {
              component.isCollapsed = false;
              this.getSemiComponentsBelongingToTheSelectedComponent(component);
            } else {
              this.sharedService.updateTriggerForPageLoad(true); // trigger the page to laod only when the page is loaded
            }
          });
        }
      }
    });
  }
  ngOnInit(): void {}
  getComponents(moduleId: string) {
    //if moduleId exists in the local storage, use it, else we wont

    if (moduleId && this.authService.UserValue?.id) {
      this.backendService
        .getComponentsForModule(this.authService.UserValue?.id!, moduleId)
        .subscribe({
          next: (components) => {
            this.refreshed = false;
            this.updateSidebar = true;
            this.components = components;
            this.moduleLink = this.router.url.split("/")[1];
            this.components.forEach((component) => {
              component.isCollapsed = true;
              if (component.componentLink == this.router.url.split("/")[2]) {
                component.isCollapsed = false;
                this.getSemiComponentsBelongingToTheSelectedComponent(
                  component
                );
              } else {
                this.sharedService.updateTriggerForPageLoad(true); // trigger the page to laod only when the page is loaded
              }
            });
          },
          error: (err) => {
            this.toast.error(err);
          },
        });
    }
  }
  setActiveHomeMenus(menu: homeMenus) {
    this.homeMenus.forEach((menu) => {
      menu.isActive = false;
    });
    menu.isActive = true;
  }
  setActiveCompsWithoutChildMenus(component: IComponent) {
    this.components.forEach((comp) => {
      comp.isCollapsed = comp === component ? false : true;
    });
    this.sharedService.updateComponentId(component.componentId);
  }

  setActiveSemiComponentMenu(semiComponent: ISemicomponents) {
    this.semicomponents.forEach((semiComp) => {
      semiComp.isCollapsed =
        semiComp.id === semiComponent.id ? !semiComp.isCollapsed : true;
    });
  }
  toggleComponentsWithChildren(component: IComponent) {
    this.selectedComponent = null;
    this.selectedComponent = component;
    this.components.forEach((comp) => {
      comp.isCollapsed = comp === component ? !comp.isCollapsed : true;
    });
    this.semicomponents = [];
    this.getSemiComponentsBelongingToTheSelectedComponent(component);
  }

  getSemiComponentsBelongingToTheSelectedComponent(menu: IComponent) {
    //For some components, we will need to send its id to where it is needed.
    //     // Mostly will be the ecommerce module's component
    //     //We will compare the component's parent id with the module id
    this.sharedService.updateComponentId(menu.componentId);
    this.backendService
      .getSemiComponentsForComponent(
        this.authService.UserValue?.id,
        menu.componentId
      )
      .subscribe((semicomponents) => {
        this.sharedService.updateTriggerForPageLoad(true); // trigger the page to laod only when the page is loaded
        semicomponents.forEach((semiComp) => {
          if (semiComp.semiComponentLink == this.router.url.split("/")[3]) {
            semiComp.isCollapsed = false;
            return;
          }
          semiComp.isCollapsed = true;
        });
        this.semicomponents = semicomponents;
      });
  }
}

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

// Component pages
import { DashboardComponent } from "./dashboards/dashboard/dashboard.component";
import { PagesGuard } from "../core/guards/auth.guard";

const routes: Routes = [
  {
    path: "",
    component: DashboardComponent,
  },
  {
    path: "",
    loadChildren: () =>
      import("./dashboards/dashboards.module").then((m) => m.DashboardsModule),
  },
  {
    path: "organization",
    // loadComponent: () =>
    //   import("./organization/main/main.component").then((m) => m.MainComponent
    loadChildren: () =>
      import("./homePage/organization/organization.module").then((m) => m.OrganizationModule),
  },
  {
    path: "membership",
    loadChildren: () =>
      import("./homePage/membrship/membrship.module").then((m) => m.MembrshipModule),
  },
  {
    path: "attendance",
    loadChildren: () =>
      import("./homePage/attendance/attendance.module").then((m) => m.AttendanceModule),
  },
  {
    path: "financial",
    loadChildren: () =>
      import("./homePage/financial/financial.module").then((m) => m.FinancialModule),
  },
  {
    path: "broadcast",
    loadChildren: () =>
      import("./homePage/broadcast/broadcast.module").then((m) => m.BroadcastModule),
  },
  //module routes
  {
    path: "society-vestry",
    loadChildren: () =>
      import("./adminPages/society-office/society-office.module").then(
        (m) => m.SocietyOfficeModule
      ),
    canActivate: [PagesGuard],
  },
  // {
  //   path: "circuit-vestry",
  //   loadChildren: () =>
  //     import("./adminPages/circuit/circuit.module").then(
  //       (m) => m.CircuitModule
  //     ),
  //   canActivate: [PagesGuard],
  // },
  // {
  //   path: "diocese-vestry",
  //   loadChildren: () =>
  //     import("./adminPages/diocesan/diocesan.module").then(
  //       (m) => m.DiocesanModule
  //     ),
  //   canActivate: [PagesGuard],
  // },
  {
    path: "publications",
    loadChildren: () =>
      import("./adminPages/website/website.module").then(
        (m) => m.WebsiteModule
      ),
    canActivate: [PagesGuard],
  },
  {
    path: "shops",
    loadChildren: () =>
      import("./adminPages/e-commerce/e-commerce.module").then(
        (ec) => ec.ECommerceModule
      ),
    canActivate: [PagesGuard],
  },
  // {
  //   path: "office",
  //   loadChildren: () =>
  //     import("./adminPages/administration/administration.module").then(
  //       (m) => m.AdministrationModule
  //     ),
  //   canActivate: [PagesGuard],
  // },
  // {
  //   path: "finance",
  //   loadChildren: () =>
  //     import("./adminPages/financial/financial.module").then(
  //       (m) => m.FinancialModule
  //     ),
  //   canActivate: [PagesGuard],
  // },
  {
    path: "role-management",
    loadChildren: () =>
      import("./roles-module/roles-module.module").then(
        (m) => m.RolesModuleModule
      ),
    title: "Role Management",
    canActivate: [PagesGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}

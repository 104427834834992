import { Component, Input } from "@angular/core";

@Component({
  selector: "app-pending",
  templateUrl: "./pending.component.html",
  styleUrls: ["./pending.component.scss"],
})
export class PendingComponent {
  @Input() pageName: string = "Page";
}

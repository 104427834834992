<!-- Vertical layout -->
<!-- @if(isVerticalLayoutRequested()){ -->
<app-vertical></app-vertical>
<!-- } -->
<!-- Horizontal layout -->
<!-- @if(isHorizontalLayoutRequested()){
<app-horizontal></app-horizontal>
} -->

<!-- Two Columns layout -->
<!-- @if(isTwoColumnLayoutRequested()){
<app-two-column></app-two-column>
} -->

<!-- Semibox layout -->
<!-- @if(isSemiboxLayoutRequested()){
<app-vertical></app-vertical>
} -->

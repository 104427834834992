import { Component, OnInit, OnDestroy } from "@angular/core";
import { HotToastService } from "@ngxpert/hot-toast";
import { SharedService } from "src/app/core/services/shared-service.service";
import { backendService } from "src/app/core/services/backendService.service";
import { AuthService } from "src/app/core/services/authentication.service";
import { IComponent } from "src/app/core/utils/Icomponents";
import { Subscription } from "rxjs";
import { IsideMenu } from "./core/utils/sidemenu.interface";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "The Methodist Church Ghana";
  menu!: IsideMenu;
  moduleId: string = "";

  subscriptions = new Subscription();
  constructor(
    private sharedService: SharedService,
    private backendService: backendService,
    private authService: AuthService,
    private toast: HotToastService
  ) {}

  ngOnInit() {
    //let's ping the internet connection
    //show toaster when the internet connection is off or connected.
    window.addEventListener("online", this.handleOnlineStatus.bind(this));
    this.toast.close();
    window.addEventListener("offline", this.handleOfflineStatus.bind(this));

    //On page load lets fetch the components belonging to the module and update the side menus
    this.getComponents();
  }

  handleOnlineStatus() {
    this.toast.close();
    this.toast.success("Internet connection restored", {
      position: "bottom-left",
    });
  }

  handleOfflineStatus() {
    this.toast.close();
    this.toast.warning("Internet connection OFFLINE", {
      position: "bottom-left",
      autoClose: false,
      id: "pause",
      dismissible: true,
    });
  }
  getComponents() {
    //if moduleId exists in the local storage, use it, else we wont
    let moduleId;
    this.sharedService.selectedModule$.subscribe((module) => {
      moduleId = module.moduleId;
      if (moduleId && this.authService.UserValue?.id) {
        this.subscriptions.add(
          this.backendService
            .getComponentsForModule(this.authService.UserValue?.id!, moduleId)
            .subscribe({
              next: (components) => {
                this.menu = {
                  showStaticSideMenu: false,
                  components: components,
                  moduleName: null,
                  moduleIcon: null,
                };
                this.updateSideMenu(this.menu);
              },
              error: (err) => {
                this.toast.error(err);
              },
            })
        );
      }
    });
  }
  updateSideMenu(menu: IsideMenu) {
    this.sharedService.updateSideMenu(menu);
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    window.removeEventListener("online", this.handleOnlineStatus.bind(this));
    window.removeEventListener("offline", this.handleOfflineStatus.bind(this));
  }
}

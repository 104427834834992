//this file contains all reusable functions
import { Injectable } from "@angular/core";
import {
  format,
  parseISO,
  getYear,
  getISOWeek,
  parse,
  isValid,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
} from "date-fns";
import { DatePipe } from "@angular/common";
import { ModulesAndComponents } from "../utils/IModulesAndComponents";
import { Router } from "@angular/router";
@Injectable({
  providedIn: "root",
})
export class generalFunctions {
  constructor(private router: Router) {}
  month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  today = new Date();
  data!: ModulesAndComponents;

  // Function to format a date object as "2nd March 2013"
  formatDate(date: Date): string {
    const day = this.addOrdinalSuffix(date.getDate());
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  getTodaysFullDate() {
    // We will concat today's day, month and year to a single date
    const d = new Date();
    // get this month
    const month = this.month[d.getMonth()];
    // get this day with the surfix
    const day = this.addOrdinalSuffix(d.getDate());
    //get this year
    const year = d.getFullYear();

    //finally put them all together
    return `${day} ${month}, ${year}`;
  }

  acceptedDateFormat(date: Date) {
    // Output: "yyyy-mm-dd hh:mm:ss" format of the current date and time

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  // Function to add the ordinal suffix to a day number
  private addOrdinalSuffix(day: number): string {
    if (day % 10 === 1 && day !== 11) {
      return day + "st";
    } else if (day % 10 === 2 && day !== 12) {
      return day + "nd";
    } else if (day % 10 === 3 && day !== 13) {
      return day + "rd";
    } else {
      return day + "th";
    }
  }

  //this function will change the format of the date from "yyyy-MM-dd HH:mm:ss" to "2nd January 2023"
  convertRegDate(date: string) {
    const year = date.substring(0, 4);
    const month = this.month[parseInt(date.substring(5, 7)) - 1];
    const day = this.addOrdinalSuffix(parseInt(date.substring(8, 10)));

    return `${day} ${month} ${year}`;
  }

  getWeekNumber() {
    return getISOWeek(this.today);
  }

  getFormattedDate() {
    return format(this.today, "dd/MM/yyyy HH:mm:ss");
  }

  formatThisDate(date: Date) {
    return format(date, "dd/MM/yyyy HH:mm:ss");
  }

  getThisYear() {
    return getYear(this.today);
  }

  /**convert incomming date "2023-08-23T15:24:52.82" to readable format 'EEEE, dd/MM/yyyy',
   * @return {string} formatted date 'EEEE, dd/MM/yyyy'
   * @param {string} date - date to be formatted
   */
  convertIncomingDate(date: string): string {
    const parsedDate = parseISO(date);
    const dateFormat = "EEEE, MMMM dd, yyyy";

    if (isValid(parsedDate)) {
      return format(parsedDate, dateFormat);
    }
    return date;
  }
  /**convert the date you are about to send to a format for the backend
   * @returns {string} formatted date "2023-08-23T15:24:52.82"
   * @param {string} date - the date you are about to send
   */
  convertDateToBeSent(date: string): string {
    const dateFormat = new Date(date).toISOString();
    // const formatDate = this.datePipe.transform(
    //   dateFormat,
    //   "yyyy-MM-ddTHH:mm:ss.SSSZ",
    //   "UTC"
    // );
    return dateFormat;
  }

  /**Count the number of days between 2 dates
   * @param startDate - the start date
   * @param endDate - the end date
   * @returns {string} - the number of days between the two dates
   */
  getDaysBetween(startDate: string, endDate: string): string {
    let sDate = new Date(startDate);
    let eDate = new Date(endDate);
    const daysDifference = differenceInDays(eDate, sDate);
    const weeksDifference = differenceInWeeks(eDate, sDate);
    const monthsDifference = differenceInMonths(eDate, sDate);
    const yearsDifference = differenceInYears(eDate, sDate);

    let dateDifference!: string;
    if (daysDifference === 1 || daysDifference === 0) {
      dateDifference = `1 day`;
    } else if (daysDifference > 1 && daysDifference < 31) {
      const daysLeft = daysDifference % 7;
      dateDifference =
        `${
          weeksDifference >= 1
            ? `${weeksDifference} Week${weeksDifference > 1 ? "s" : ""}`
            : ``
        }` +
        `${daysLeft > 0 ? ` ${daysLeft} Day${daysLeft > 1 ? "s" : ""}` : ``}`;
    } else if (daysDifference >= 31 && daysDifference < 365) {
      let daysLeft = daysDifference % 31;
      //lets first of all find the weeks left in the remaining days
      let weeksLeft = Math.floor(daysLeft / 7);
      // and the rest of the days
      let daysLeftOver = daysLeft % 7;

      dateDifference =
        `${monthsDifference} Month${monthsDifference > 1 ? "s" : ""}` +
        `${
          weeksLeft >= 1 ? ` ${weeksLeft} Week${weeksLeft > 1 ? "s" : ""}` : ``
        }` +
        `${
          daysLeftOver >= 1
            ? ` ${daysLeftOver} Day${daysLeftOver > 1 ? "s" : ""}`
            : ``
        }`;
    } else if (daysDifference >= 365) {
      let daysLeft = daysDifference % 365;
      let monthsLeft = Math.floor(daysLeft / 31);
      let daysForWeeksLeft = daysLeft % 31;
      let weeksLeft = Math.floor(daysForWeeksLeft / 7);
      let daysLeftOver = daysForWeeksLeft % 7;

      dateDifference =
        `${yearsDifference} Year${yearsDifference > 1 ? "s" : ""}` +
        `${
          monthsLeft >= 1
            ? ` ${monthsLeft} Month${monthsLeft > 1 ? "s" : ""}`
            : ``
        }` +
        `${
          weeksLeft >= 1 ? ` ${weeksLeft} Week${weeksLeft > 1 ? "s" : ""}` : ``
        }` +
        `${
          daysLeftOver >= 1
            ? ` ${daysLeftOver} Day${daysLeftOver > 1 ? "s" : ""}`
            : ``
        }`;
    }

    return dateDifference;
  }

  convertPickedDate(date: string): string {
    const inputDate = new Date(date);
    // Convert the date to ISO 8601 format
    const isoDateString = inputDate.toISOString();
    return isoDateString;
  }

  getData(): ModulesAndComponents {
    // console.log(localStorage.getItem("RolesAndPerms"))
    let parsedData = JSON.parse(localStorage.getItem("RolesAndPerms")!);
    parsedData ? (this.data = JSON.parse(parsedData)) : console.log("No roles");

    //we need to modify the arry data to remove all '/dashboard' characters from the items that have them
    this.data.Modules = this.data.Modules.map((item: any) =>
      item.replace("/overview", "")
    );
    return this.data;
  }

  checkRoutes(requestRoutes: string[]): boolean {
    //lets get the routes from the modules, components, semi components, mini components and nano components
    const routes = this.getData();
    //add dashboard and auth to components so that when request to those routes is hit, it does not fail
    // routes.Components.push(
    //   ...[
    //     "dashboard",
    //     "login",
    //     "member-register",
    //     "guest-register",
    //     "confirm-registration",
    //     "forgot-password",
    //   ]
    // );
    routes.Components.push("overview");
    //define arrays to cross-reference
    const arraysToCheck = [
      routes.Modules,
      routes.Components,
      routes.SemiComponents,
      routes.MiniComponents,
      routes.NanoComponents,
    ];
    //loop through the requestedRoutes array and check each index against the correspondindg arrays
    //start with the first index because the first index containd the base route 'localhost or www.methodist.com'
    for (let i = 0; i < requestRoutes.length; i++) {
      if (arraysToCheck[i].includes(requestRoutes[i])) {
        continue; //route was found, continue to the next index
      } else {
        return false; //route was not found so return a false value
      }
    }

    //if all the routes were found then return true
    return true;
  }

  /**
   * Returns the church code from a string by slicing the first seven (7) characters
   * @param username The username of the logged in user
   * @returns Church code as a string
   */
  getChurchCodeFromUsername(username: string) {
    return username.substring(0, 7);
  }

  /**
   * Returns the day, month and year of the provided date in an object
   * @param date Date to be converted
   * @returns Object containing day, month and year
   */
  getDateIntoDayMonthYear(date: string): {
    day: number;
    month: string;
    year: number;
  } {
    if (date === undefined || date === null)
      throw new Error("Date cannot be null or undefined");

    let dt = new Date(date);
    let month = this.month[dt.getMonth()];
    let day = dt.getDate();
    let year = dt.getFullYear();

    return { day, month, year };
  }

  /**Get Shortened text
   * @param  {string} text - The text to be shortened
   * @param {number} limit - The length to be returned
   * @return {string} - The shortened text
   */
  getShortenedText(text: string, limit: number): string {
    if (text.length <= limit) {
      return text;
    }
    return text.substring(0, limit) + "...";
  }

  convertCRUDToReadableArrayForRolesSelection(text: string): string[] {
    const finalArray: string[] = []; //this will contain all the permissions in the readable form
    const crudMap: { [key: string]: string } = {
      c: "add",
      r: "view",
      u: "edit",
      d: "delete",
    };
    //loop through the text length and replace all the characters in the new array
    for (let i = 0; i < text.length; i++) {
      const char = text[i].toLowerCase(); //making sure the character is lowercase
      const exists = crudMap[char];
      if (exists) {
        finalArray.push(exists);
      }
    }
    return finalArray;
  }

  replacewithCRUD(text: string) {
    return text
      .replace(/add/g, "c")
      .replace(/edit/g, "u")
      .replace(/view/g, "r")
      .replace(/delete/g, "d");
  }

  /**CONVERT TO ARRAY
   * In this fuction we will convert any incomming array of objects and only store an array of
   * it's values
   */
  convertDataToArray(data: any[]) {
    const resultArray = data.map((obj) => Object.values(obj));
    return resultArray;
  }

  formatTime(date: Date): string {
    return format(date, "HH:mm:ss"); // Formats to "04:30:11"
  }
}

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { LayoutComponent } from "./layouts/layout.component";

// Auth
import { AuthGuard, AdminGuard } from "./core/guards/auth.guard";

const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
    canActivate: [AdminGuard],
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./account/account.module").then((m) => m.AccountModule),
  },
  //when a page is not found it will redirect to the 404 page
  { path: "**", redirectTo: "/" },
  // {
  //   path: "pages",
  //   loadChildren: () =>
  //     import("./extraspages/extraspages.module").then(
  //       (m) => m.ExtraspagesModule
  //     ),
  // },
  // {
  //   path: "landing",
  //   loadChildren: () =>
  //     import("./landing/landing.module").then((m) => m.LandingModule),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

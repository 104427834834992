<div class="ontop">
  <ng-select
    [items]="items"
    bindLabel="{{ bindLabel }}"
    [(ngModel)]="value"
    (close)="clearItems()"
    (focus)="loadItems()"
    (change)="onOptionSelected($event)"
    (open)="cona()"
    placeholder="Select {{ itemName }}"
    [notFoundText]="loadingText"
  ></ng-select>

  @if(loading){
  <div class="testt">
    <div id="wave">
      <span class="text-muted" style="margin-left: 10px"
        >Loading {{ itemName }}</span
      >
      <div class="waveform">
        <div class="waveform__bar"></div>
        <div class="waveform__bar"></div>
        <div class="waveform__bar"></div>
        <div class="waveform__bar"></div>
      </div>
    </div>
  </div>
  }
</div>

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  NgbNavModule,
  NgbAccordionModule,
  NgbDropdownModule,
} from "@ng-bootstrap/ng-bootstrap";

// Swiper Slider
import { RouterModule } from "@angular/router";
// Counter
import { CountUpModule } from "ngx-countup";

import { NzSkeletonModule } from "ng-zorro-antd/skeleton";

//shared through app
import { SelectLoaderComponent } from "./select-loader/select-loader.component";

import { NgSelectModule } from "@ng-select/ng-select";
import { QuantityChangerComponent } from "./quantity-changer/quantity-changer.component";
import { FormsModule } from "@angular/forms";
import { PreloaderComponent } from "./preloader/preloader.component";
import { PendingComponent } from "./pending/pending.component";
import { PageNavbarComponent } from "./page-navbar/page-navbar.component";
import { RenderHtmlComponent } from "./render-html/render-html.component";
// import { OptionsComponent } from "./options/options.component";
// import { CCkdeditorModule } from "./c-ckdeditor/c-ckdeditor.module";
// Load Icons
import { defineElement } from "@lordicon/element";
import lottie from "lottie-web";
@NgModule({
  declarations: [
    SelectLoaderComponent,
    QuantityChangerComponent,
    PreloaderComponent,
    PendingComponent,
    PageNavbarComponent,
    RenderHtmlComponent,
    SelectLoaderComponent,
  ],
  imports: [
    CommonModule,
    NgbNavModule,
    NgbAccordionModule,
    NgbDropdownModule,
    CountUpModule,
    RouterModule,
    NgSelectModule,
    FormsModule,
    NzSkeletonModule,
    // CCkdeditorModule,
  ],
  exports: [
    PreloaderComponent,
    PendingComponent,
    PageNavbarComponent,
    RenderHtmlComponent,
    // OptionsComponent,
    SelectLoaderComponent,
    QuantityChangerComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  constructor() {
    defineElement(lottie.loadAnimation);
  }
}

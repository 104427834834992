<div class="d-flex slice text-center">
  <button (click)="decrease()">
    <i class="ri-checkbox-indeterminate-fill"></i>
  </button>
  <input
    type="number"
    [(ngModel)]="currentValue"
    (keyup)="getnum(currentValue)"
  />
  <button (click)="increase()">
    <i class="ri-add-box-fill"></i>
  </button>
</div>

import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-select-loader",
  templateUrl: "./select-loader.component.html",
  styleUrl: "./select-loader.component.scss",
})
export class SelectLoaderComponent {
  loading: boolean = false;
  loadingText = ".";
  @Input() value!: any;
  @Input() itemName: string = "items";
  @Input() bindLabel: string = "name";
  @Output() optionsSelected: EventEmitter<any> = new EventEmitter();
  @Output() itemsFocused: EventEmitter<any> = new EventEmitter();
  @Output() clearList: EventEmitter<boolean> = new EventEmitter();
  private _items: { [key: string]: any }[] | null = null;
  @Input() set items(value: { [key: string]: any }[] | null) {
    this._items = value;
  }

  get items(): { [key: string]: any }[] | null {
    if (this._items) {
      this.loading = false;
      this._items.length == 0
        ? (this.loadingText = `No ${this.itemName} available`)
        : 0; //do nothing
    }
    return this._items;
  }
  loadItems() {
    this.loading = true;
    this.itemsFocused.emit();
  }
  cona() {
    this.items = null;
    this.loading = true;
    this.itemsFocused.emit();
  }

  clearItems() {
    this.loading = false;
    this.items = null;
    this.clearList.emit(true);
  }

  onOptionSelected(selectedOption: any) {
    this.optionsSelected.emit(selectedOption);
  }
}

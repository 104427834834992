/**JWT INTERCEPTOR
 * The JWT Interceptor intercepts http requests from the application
 *  to add a JWT auth token to the Authorization header if the user is
 *  logged in and the request is to the application api url
 */
import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";

import { backendAPI } from "../../../environments/environment";
import { AuthService } from "../services/authentication.service";
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authservice: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const currUser = this.authservice.UserValue;
    // const isLoggedIn = currUser && currUser.token;
    const isLoggedIn = currUser && currUser.token;

    const isApiUrl = request.url.startsWith(backendAPI.authAPI);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currUser.token}`,
        },
      });
    }

    return next.handle(request);
  }
}

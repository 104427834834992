import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Inject,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { EventService } from "../../core/services/event.service";

//Logout
// import { environment } from "../../../environments/environment";
// import { AuthenticationService } from '../../core/services/auth.service';
// import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { Router } from "@angular/router";
import { TokenStorageService } from "../../core/services/token-storage.service";
import { AuthService } from "src/app/core/services/authentication.service";
import { backendService } from "src/app/core/services/backendService.service";
import { HotToastService } from "@ngxpert/hot-toast";
import {
  Imodules,
  SharedService,
} from "src/app/core/services/shared-service.service";
// Language
// import { CookieService } from "ngx-cookie-service";
// import { LanguageService } from "../../core/services/language.service";
// import { TranslateService } from "@ngx-translate/core";
// import { allNotification, messages } from "./data";
// import { cartData } from "./data";
// import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ImodulesInterface } from "src/app/core/utils/Imodules";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})
export class TopbarComponent implements OnInit {
  messages: any;
  element: any;
  mode: string | undefined;
  @Output() mobileMenuButtonClicked = new EventEmitter();
  allnotifications: any;
  flagvalue: any;
  valueset: any;
  countryName: any;
  cookieValue: any;
  userData: any;
  cartData!: any[];
  total = 0;
  cart_length: any = 0;
  totalNotify: number = 0;
  newNotify: number = 0;
  readNotify: number = 0;
  isDropdownOpen = false;
  @ViewChild("removenotification") removenotification!: TemplateRef<any>;
  notifyId: any;
  societyName: string | undefined;
  userName: string | undefined;
  email: string | undefined;
  hasModule!: boolean;
  showBadge!: boolean;
  loaded!: boolean;
  modules!: ImodulesInterface[];
  lastModule: any;
  constructor(
    @Inject(DOCUMENT) private document: any,
    // private eventService: EventService,
    // public languageService: LanguageService,
    // private modalService: NgbModal,
    // public _cookiesService: CookieService,
    // public translate: TranslateService,
    //  private authService: AuthenticationService,
    // private authFackservice: AuthfakeauthenticationService,
    // private router: Router,
    private TokenStorageService: TokenStorageService,
    private authService: AuthService,
    private backendService: backendService,
    private toast: HotToastService,
    private sharedService: SharedService
  ) {
    this.societyName = this.authService.UserValue?.societyName;
    this.userName = this.authService.UserValue?.firstName;
    this.email = this.authService.UserValue?.email;
  }

  ngOnInit(): void {
    this.userData = this.TokenStorageService.getUser();
    this.element = document.documentElement;

    // Cookies wise Language set
    // this.cookieValue = this._cookiesService.get("lang");
    // const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    // this.countryName = val.map((element) => element.text);
    // if (val.length === 0) {
    //   if (this.flagvalue === undefined) {
    //     this.valueset = "assets/images/flags/us.svg";
    //   }
    // } else {
    //   this.flagvalue = val.map((element) => element.flag);
    // }

    // Fetch Data
    // this.allnotifications = allNotification;

    // this.messages = messages;
    // this.cartData = cartData;
    // this.cart_length = this.cartData.length;
    // this.cartData.forEach((item) => {
    //   var item_price = item.quantity * item.price;
    //   this.total += item_price;
    // });

    this.backendService
      .getModulesForMember(this.authService.UserValue?.id)
      .subscribe({
        next: (res) => {
          //when there is content, show button

          //Update the modules of the person
          if (res.length > 0) {
            let modules: Imodules[] = res.map((item) => {
              return {
                moduleId: item.moduleId,
                moduleLink: item.moduleLink,
              };
            });

            this.sharedService.updateModules(modules);
            this.hasModule = true;
            //whiles here lets check the previous number of modules belonging to a user to notify the user when the new modules are assigned on login
            //if localstorage exists, compare with to new response to see if there is any new item added or removed

            if (localStorage.getItem("numberOfModules")) {
              if (
                res.length != parseInt(localStorage.getItem("numberOfModules")!)
              ) {
                this.showBadge = true;
                //now replace old number of modules with new one
                localStorage.setItem("numberOfModules", res.length.toString());
              }
            }
            //else if localstorage doesn't exist, it means it's first time login and thus we will show the badge
            else {
              this.showBadge = true;
            }
          } else {
            this.hasModule = false;
          }
        },
      });
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  // toggleMobileMenu(event: any) {
  //   document.querySelector(".hamburger-icon")?.classList.toggle("open");
  //   event.preventDefault();
  //   this.mobileMenuButtonClicked.emit();
  // }

  /**
   * Fullscreen method
   */
  // fullscreen() {
  //   document.body.classList.toggle("fullscreen-enable");
  //   if (
  //     !document.fullscreenElement &&
  //     !this.element.mozFullScreenElement &&
  //     !this.element.webkitFullscreenElement
  //   ) {
  //     if (this.element.requestFullscreen) {
  //       this.element.requestFullscreen();
  //     } else if (this.element.mozRequestFullScreen) {
  //       /* Firefox */
  //       this.element.mozRequestFullScreen();
  //     } else if (this.element.webkitRequestFullscreen) {
  //       /* Chrome, Safari and Opera */
  //       this.element.webkitRequestFullscreen();
  //     } else if (this.element.msRequestFullscreen) {
  //       /* IE/Edge */
  //       this.element.msRequestFullscreen();
  //     }
  //   } else {
  //     if (this.document.exitFullscreen) {
  //       this.document.exitFullscreen();
  //     } else if (this.document.mozCancelFullScreen) {
  //       /* Firefox */
  //       this.document.mozCancelFullScreen();
  //     } else if (this.document.webkitExitFullscreen) {
  //       /* Chrome, Safari and Opera */
  //       this.document.webkitExitFullscreen();
  //     } else if (this.document.msExitFullscreen) {
  //       /* IE/Edge */
  //       this.document.msExitFullscreen();
  //     }
  //   }
  // }
  /**
   * Open modal
   * @param content modal content
   */
  // openModal(content: any) {
  //   // this.submitted = false;
  //   this.modalService.open(content, { centered: true });
  // }

  /***********MENUS *********
   * We will fetch and display all the modules belonging to the logged in user
   */
  getModules() {
    //first set the modules and loaded to null and false
    this.modules = [];
    this.lastModule = null;
    this.loaded = false;
    this.backendService
      .getModulesForMember(this.authService.UserValue?.id)
      .subscribe({
        next: (modules) => {
          //set loaded to true
          this.loaded = true;
          localStorage.setItem("numberOfModules", modules.length.toString());
          this.modules = modules.sort((a, b) => a.moduleSort - b.moduleSort);
          this.lastModule = this.modules.pop();
        },
        error: (error) => {
          this.toast.error(error);
        },
      });
  }

  selectModule(module: ImodulesInterface) {
    // set the badge to not show
    this.showBadge = false;
    this.sharedService.updateTriggerForPageLoad(false);
    //we will need the moduleId of the selected Id for fetching the components that belong to the user
    this.sharedService.updateSelectedModule({
      moduleId: module.moduleId,
      moduleLink: module.moduleLink,
    });

    // localStorage.removeItem("moduleId"); //remove any old moduleId data
    // localStorage.setItem("moduleId", module.moduleId);
    //We will update the loading to true to show the loading preloader on the sidebar
    // next we will fetch the components that belongs to the module pressed
    // this.backendService
    //   .getComponentsForModule(this.authService.UserValue?.id, module.moduleId)
    //   .subscribe({
    //     next: (components) => {
    //       console.log(components);
    //       //once the components are fetched, we will update the shared service with the components
    //       this.sharedService.updateSideMenu({
    //         showStaticSideMenu: false,
    //         components: components,
    //         moduleName: module.moduleName,
    //         moduleIcon: module.moduleLogoLink,
    //       });
    //     },
    //     error: (error) => {
    //       this.toast.error(error);
    //     },
    //   });
  }

  /**
   * Topbar Light-Dark Mode Change
   */
  // changeMode(mode: string) {
  //   this.mode = mode;
  //   this.eventService.broadcast("changeMode", mode);

  //   switch (mode) {
  //     case "light":
  //       document.documentElement.setAttribute("data-bs-theme", "light");
  //       break;
  //     case "dark":
  //       document.documentElement.setAttribute("data-bs-theme", "dark");
  //       break;
  //     default:
  //       document.documentElement.setAttribute("data-bs-theme", "light");
  //       break;
  //   }
  // }

  /***
   * Language Listing
  //  */
  // listLang = [
  //   { text: "English", flag: "assets/images/flags/us.svg", lang: "en" },
  //   { text: "Española", flag: "assets/images/flags/spain.svg", lang: "es" },
  //   { text: "Deutsche", flag: "assets/images/flags/germany.svg", lang: "de" },
  //   { text: "Italiana", flag: "assets/images/flags/italy.svg", lang: "it" },
  //   { text: "русский", flag: "assets/images/flags/russia.svg", lang: "ru" },
  //   { text: "中国人", flag: "assets/images/flags/china.svg", lang: "ch" },
  //   { text: "français", flag: "assets/images/flags/french.svg", lang: "fr" },
  //   { text: "Arabic", flag: "assets/images/flags/ar.svg", lang: "ar" },
  // ];

  /***
   * Language Value Set
   */
  // setLanguage(text: string, lang: string, flag: string) {
  //   this.countryName = text;
  //   this.flagvalue = flag;
  //   this.cookieValue = lang;
  //   this.languageService.setLanguage(lang);
  // }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
    // localStorage.clear();
    // this.router.navigate(["/auth/login"]);
  }

  // // windowScroll() {
  // //   if (
  // //     document.body.scrollTop > 100 ||
  // //     document.documentElement.scrollTop > 100
  // //   ) {
  // //     (document.getElementById("back-to-top") as HTMLElement).style.display =
  // //       "block";
  // //     document.getElementById("page-topbar")?.classList.add("topbar-shadow");
  // //   } else {
  // //     (document.getElementById("back-to-top") as HTMLElement).style.display =
  // //       "none";
  // //     document.getElementById("page-topbar")?.classList.remove("topbar-shadow");
  // //   }
  // // }

  // // Delete Item
  // deleteItem(event: any, id: any) {
  //   var price = event.target
  //     .closest(".dropdown-item")
  //     .querySelector(".item_price").innerHTML;
  //   var Total_price = this.total - price;
  //   this.total = Total_price;
  //   this.cart_length = this.cart_length - 1;
  //   this.total > 1
  //     ? ((document.getElementById("empty-cart") as HTMLElement).style.display =
  //         "none")
  //     : ((document.getElementById("empty-cart") as HTMLElement).style.display =
  //         "block");
  //   document.getElementById("item_" + id)?.remove();
  // }

  // toggleDropdown(event: Event) {
  //   event.stopPropagation();
  //   if (this.isDropdownOpen) {
  //     this.isDropdownOpen = false;
  //   } else {
  //     this.isDropdownOpen = true;
  //   }
  // }
  // // Search Topbar
  // Search() {
  //   var searchOptions = document.getElementById(
  //     "search-close-options"
  //   ) as HTMLAreaElement;
  //   var dropdown = document.getElementById(
  //     "search-dropdown"
  //   ) as HTMLAreaElement;
  //   var input: any,
  //     filter: any,
  //     ul: any,
  //     li: any,
  //     a: any | undefined,
  //     i: any,
  //     txtValue: any;
  //   input = document.getElementById("search-options") as HTMLAreaElement;
  //   filter = input.value.toUpperCase();
  //   var inputLength = filter.length;

  //   if (inputLength > 0) {
  //     dropdown.classList.add("show");
  //     searchOptions.classList.remove("d-none");
  //     var inputVal = input.value.toUpperCase();
  //     var notifyItem = document.getElementsByClassName("notify-item");

  //     Array.from(notifyItem).forEach(function (element: any) {
  //       var notifiTxt = "";
  //       if (element.querySelector("h6")) {
  //         var spantext = element
  //           .getElementsByTagName("span")[0]
  //           .innerText.toLowerCase();
  //         var name = element.querySelector("h6").innerText.toLowerCase();
  //         if (name.includes(inputVal)) {
  //           notifiTxt = name;
  //         } else {
  //           notifiTxt = spantext;
  //         }
  //       } else if (element.getElementsByTagName("span")) {
  //         notifiTxt = element
  //           .getElementsByTagName("span")[0]
  //           .innerText.toLowerCase();
  //       }
  //       if (notifiTxt)
  //         element.style.display = notifiTxt.includes(inputVal)
  //           ? "block"
  //           : "none";
  //     });
  //   } else {
  //     dropdown.classList.remove("show");
  //     searchOptions.classList.add("d-none");
  //   }
  // }

  /**
   * Search Close Btn
   */
  // closeBtn() {
  //   var searchOptions = document.getElementById(
  //     "search-close-options"
  //   ) as HTMLAreaElement;
  //   var dropdown = document.getElementById(
  //     "search-dropdown"
  //   ) as HTMLAreaElement;
  //   var searchInputReponsive = document.getElementById(
  //     "search-options"
  //   ) as HTMLInputElement;
  //   dropdown.classList.remove("show");
  //   searchOptions.classList.add("d-none");
  //   searchInputReponsive.value = "";
  // }

  // // Remove Notification
  // checkedValGet: any[] = [];
  // onCheckboxChange(event: any, id: any) {
  //   this.notifyId = id;
  //   var result;
  //   if (id == "1") {
  //     var checkedVal: any[] = [];
  //     for (var i = 0; i < this.allnotifications.length; i++) {
  //       if (this.allnotifications[i].state == true) {
  //         result = this.allnotifications[i].id;
  //         checkedVal.push(result);
  //       }
  //     }
  //     this.checkedValGet = checkedVal;
  //   } else {
  //     var checkedVal: any[] = [];
  //     for (var i = 0; i < this.messages.length; i++) {
  //       if (this.messages[i].state == true) {
  //         result = this.messages[i].id;
  //         checkedVal.push(result);
  //       }
  //     }
  //     console.log(checkedVal);
  //     this.checkedValGet = checkedVal;
  //   }
  //   checkedVal.length > 0
  //     ? ((
  //         document.getElementById("notification-actions") as HTMLElement
  //       ).style.display = "block")
  //     : ((
  //         document.getElementById("notification-actions") as HTMLElement
  //       ).style.display = "none");
  // }

  // notificationDelete() {
  //   if (this.notifyId == "1") {
  //     for (var i = 0; i < this.checkedValGet.length; i++) {
  //       for (var j = 0; j < this.allnotifications.length; j++) {
  //         if (this.allnotifications[j].id == this.checkedValGet[i]) {
  //           this.allnotifications.splice(j, 1);
  //         }
  //       }
  //     }
  //   } else {
  //     for (var i = 0; i < this.checkedValGet.length; i++) {
  //       for (var j = 0; j < this.messages.length; j++) {
  //         if (this.messages[j].id == this.checkedValGet[i]) {
  //           this.messages.splice(j, 1);
  //         }
  //       }
  //     }
  //   }
  //   this.calculatenotification();
  //   this.modalService.dismissAll();
  // }

  // calculatenotification() {
  //   this.totalNotify = 0;
  //   this.checkedValGet = [];

  //   this.checkedValGet.length > 0
  //     ? ((
  //         document.getElementById("notification-actions") as HTMLElement
  //       ).style.display = "block")
  //     : ((
  //         document.getElementById("notification-actions") as HTMLElement
  //       ).style.display = "none");
  //   if (this.totalNotify == 0) {
  //     document
  //       .querySelector(".empty-notification-elem")
  //       ?.classList.remove("d-none");
  //   }
  // }
}

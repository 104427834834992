import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { firstValueFrom } from 'rxjs';

interface IIndexes {
  begin: number;
  end: number;
}

@Component({
  selector: 'app-render-html',
  templateUrl: './render-html.component.html',
  styleUrls: ['./render-html.component.scss']
})
export class RenderHtmlComponent implements OnChanges {
  @Input() data: string | undefined = undefined;
  @ViewChild('container', { static: true }) container!: ElementRef<HTMLDivElement>;
  
  isLoaded: boolean = false;

  constructor(
    private renderer: Renderer2,
    private httpClient: HttpClient
  ) { }

  async ngOnChanges(changes: SimpleChanges) {
    const value = changes['data'].currentValue;
    let replacedText = '';
    const indexes: IIndexes[] = [];
    const replaceWith: string[] = [];

    if (value) {
      const links: string[] = [...this.getYoutubeVideoLink(value)];
      if (links.length) {
        try {
          for (let i = 0; i < links.length; i++) {
            let res = await firstValueFrom(this.httpClient.get<{ html: string }>(`https://www.youtube.com/oembed?url=${links[i]}&format=json`));
            indexes.push(...this.getTextIndexesToReplace(value));
            replaceWith.push(res.html);
          }
          replacedText = this.replaceTextInString(value, indexes, replaceWith);
        } catch {}
      } else {
        replacedText = value;
      }
      this.isLoaded = true;
      this.renderer.setProperty(this.container.nativeElement, 'innerHTML', replacedText);
    }
  }

  /**
   * Get the youtube video links
   * @param str The main string to replace the text in
   * @returns String of array
   */
  getYoutubeVideoLink(str: string | undefined) {
    if (str) {
      let beginStr = 'https://www.youtube.com/watch?';
      const splittedStr = str.split(beginStr);
      const links: string[] = [];

      for (let i = 1; i < splittedStr.length; i++) {
        links.push(beginStr + splittedStr[i].split('"')[0]);
      }
      return links;
    }
    return [];
  }

  /**
   * Returns the objects containing indexes of the beginning and ending characters of the string to take out of the main string
   * @param str The main string in which to replace the text
   * @returns Array of objects containing the indexes of the beginning character and ending character of the string to take out of the main string
   */
  getTextIndexesToReplace(str: string) {
    const beginStr = '<oembed';
    const endStr = '</oembed>';
    const oembedIndexes: IIndexes[] = [];

    for (let i = 0; i < str.length; i++) {
      if (str[i] === '<') {
        if (str.substring(i, i + beginStr.length) === beginStr) {

          for (let j = i; j < i + str.length; j++) {
            if (str[j] === '<' && str[j + 1] === '/') {
              if (str.substring(j, j + endStr.length) === endStr) {
                oembedIndexes.push({ begin: i, end: j + endStr.length - 1 });
                break;
              }
            }
          }
        }
      }
    }
    return oembedIndexes;
  }

  /**
   * 
   * @param str The main string in which to replace the text
   * @param indexes Array of objects containing the indexes of the beginning character and ending character of the string to take out of the main string
   * @param replaceWith Array of strings containing the strings to replace with
   * @returns String of the corrected string of the DOM element to display
   */
  replaceTextInString(str: string, indexes: IIndexes[], replaceWith: string[]) {
    let substring = '';
    let replacedStr = str;

    indexes.forEach((currIndexes, idx) => {
      substring = str.substring(currIndexes.begin, currIndexes.end + 1);
      replacedStr = replacedStr.replace(substring, replaceWith[idx]);
    });
    return replacedStr;
  }
}

<div class="app-menu navbar-menu">
  <div class="navbar-brand-box">
    <!-- Dark Logo-->
    <a routerLink="/" class="logo logo-dark">
      <span class="logo-sm">
        <img src="" alt="" height="22" />
      </span>
      <span class="logo-lg">
        <img
          src="assets/images/meth/logo.png"
          alt="The methodist church of Ghana"
          height="42"
        />
      </span>
    </a>
    <!-- Light Logo-->
    <a routerLink="/" class="logo logo-light">
      <span class="logo-sm">
        <img src="" alt="" height="22" />
      </span>
      <span class="logo-lg">
        <img
          src="assets/images/meth/logo.png"
          alt="The methodist church of Ghana"
          height="22"
        />
      </span>
    </a>
    <button
      type="button"
      class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
      id="vertical-hover"
    >
      <i class="ri-record-circle-line"></i>
    </button>
  </div>

  <div id="scrollbar">
    <div class="container-fluid">
      <div id="two-column-menu"></div>
      <ngx-simplebar
        class="sidebar-menu-scroll"
        style="max-height: calc(100vh - 70px)"
      >
        @if(refreshed){
        <div class="comp-placeholder1 mt-2 gloww1"></div>
        <div class="comp-placeholder2 mt-2 gloww2"></div>
        <div class="comp-placeholder3 mt-2 gloww3"></div>
        } @else {
        <ul class="metismenu list-unstyled navbar-nav" id="side-menu">
          <ng-container>
            <!-- <li class="menu-title ms-1">Home</li> -->
            @if(!updateSidebar){ @for(menu of homeMenus; track $index){
            <li class="nav-item ms-1">
              <a
                class="nav-link menu-link hovs"
                [class.active]="menu.isActive"
                (click)="setActiveHomeMenus(menu)"
                [routerLink]="[menu.path]"
              >
                <i class="{{ menu.icon }}"></i>
                <span class="ms-2"> {{ menu.name }}</span>
              </a>
            </li>
            } } @else { @for(menu of components; track $index){
            @if(menu.componentHasChild){
            <a
              class="nav-link menu-link"
              id="child{{ menu.id }}"
              data-bs-toggle="collapse"
              [attr.aria-expanded]="!menu.isCollapsed"
              aria-controls="sidebarDashboards"
              style="cursor: pointer"
              (click)="toggleComponentsWithChildren(menu)"
            >
              <span>{{ menu.componentName }}</span>
            </a>
            <div
              class="collapse menu-dropdown sub-menu"
              #collapse="ngbCollapse"
              [(ngbCollapse)]="menu.isCollapsed"
            >
              <ul class="nav nav-sm flex-column" aria-expanded="false">
                @if(semicomponents.length < 1){
                <div class="comp-placeholder1 mt-2 gloww1"></div>
                <div class="comp-placeholder2 mt-2 gloww2"></div>
                <div class="comp-placeholder3 mt-2 gloww3"></div>
                } @else { @for(semis of semicomponents;track $index){
                <li class="nav-item">
                  <a
                    class="nav-link semmenu"
                    id="noChild_{{ semis.id }}"
                    [attr.data-parent]="semis.parentId"
                    [class.active]="!semis.isCollapsed"
                    style="cursor: pointer"
                    (click)="setActiveSemiComponentMenu(semis)"
                    [routerLink]="[
                      moduleLink,
                      selectedComponent?.componentLink,
                      semis.semiComponentLink
                    ]"
                  >
                    <span>{{ semis.semiComponentName }}</span>
                  </a>
                </li>
                } }
              </ul>
            </div>
            } @else{
            <li class="nav-item ms-1">
              <a
                class="nav-link menu-link hovs"
                [class.active]="!menu.isCollapsed"
                [routerLink]="[moduleLink, menu.componentLink]"
                (click)="setActiveCompsWithoutChildMenus(menu)"
              >
                <span class=""> {{ menu.componentName }} </span>
              </a>
            </li>
            } } }
          </ng-container>
        </ul>
        }
      </ngx-simplebar>
    </div>
  </div>
</div>

<!-- <div class="text-center ">

  <svg class="ring " viewBox="25 25 50 50" stroke-width="5">
    <circle cx="50" cy="50" r="20" />
  </svg>
</div> -->
<div class="wrapperr skeleton-table">
  <div class="skeleton-row" *ngFor="let row of getRowArray()">
    <div class="skeleton-cell" *ngFor="let cell of getCellArray()"></div>
  </div>
</div>

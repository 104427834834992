import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { defineElement } from "@lordicon/element";
import lottie from "lottie-web";

@Component({
  selector: "app-empty",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./empty.component.html",
  styleUrl: "./empty.component.scss",
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EmptyComponent {
  @Input() dataName: string;
  constructor() {
    this.dataName = "Items";
    defineElement(lottie.loadAnimation);
  }
}

<div class="nav-container border-rad" [class.vertical]="isVertical">
  <div *ngFor="let buttonWithPlace of buttons; let ind = index">
    <div
      *ngIf="buttonWithPlace.place === 'left'"
      class="buttons-container"
      [class.vertical]="isVertical"
    >
      <a
        [class.vertical]="isVertical"
        *ngFor="let button of buttonWithPlace.buttons; let i = index"
        [class.active]="button.isActive"
        (click)="onClick(ind, i, buttonWithPlace.place)"
      >
        <i *ngIf="button.icon !== ''" [class]="button.icon"></i>
        <span>{{ button.label }}</span>
      </a>
    </div>
  </div>

  <span class="spacer" *ngIf="!isVertical"></span>

  <div *ngFor="let buttonWithPlace of buttons; let ind = index">
    <div *ngIf="buttonWithPlace.place === 'right'" class="buttons-container">
      <a
        *ngFor="let button of buttonWithPlace.buttons; let i = index"
        [class.active]="button.isActive"
        (click)="onClick(ind, i, buttonWithPlace.place)"
      >
        <i [class]="button.icon"></i>
        <span>{{ button.label }}</span>
      </a>
    </div>
  </div>
</div>

<div class="pre">
  <div class="text-center gap-2">
    <script src="https://cdn.lordicon.com/lordicon.js"></script>
    <lord-icon
      src="https://cdn.lordicon.com/ktsahwvc.json"
      trigger="loop"
      state="loop-jab"
      colors="primary:#e0e2e5"
      style="width: 100px; height: 100px"
    >
    </lord-icon>
  </div>
  <div class="pp p">
    <h5 class="text-muted fw-bold">
      {{ bibii }}
    </h5>
    <p class="m-0">
      {{ fullText }}
    </p>
  </div>
</div>

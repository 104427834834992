/** ERROR INTERCEPTOR
 * This is to intercept the response from the server to check if there were any errors.
 * If there is, for example, a 401 Unauthorized or a 403 forbidden response, the user will be redirected
 * and all other errors will be re-thrown to the alert do that it can be displayed to the user on the screen
 */
import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError, timer } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { AuthService } from "../services/authentication.service";
import { Router } from "@angular/router";
import { HotToastService } from "@ngxpert/hot-toast";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authservice: AuthService,
    private router: Router,
    private toast: HotToastService,
    private modal: NgbModal
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // retry({
      //   count: 2,
      //   delay: (error, retryCount) => {
      //     if (error.status === 0) {
      //       // Exponential backoff for network errors
      //       return timer(1000 * Math.pow(2, retryCount));
      //     }
      //     throw error;
      //   },
      // }),
      catchError((err: HttpErrorResponse) => {
        if ([401, 403].includes(err.status)) {
          this.modal.dismissAll();
          this.authservice.logout();
          return throwError(() => "Sorry, You are Unauthorized");
        }

        console.warn(err);
        const error = err.error?.title;
        const errorMessage = error
          ? error
          : err.status === 0
          ? "No or Bad internet Connection"
          : "Something went wrong";

        return throwError(() => errorMessage);
      })
    );
  }
}

<div id="layout-wrapper">
  <!-- Top Bar -->
  <app-topbar (mobileMenuButtonClicked)="onToggleMobileMenu()"></app-topbar>
  <!-- Side Bar -->
  <app-sidebar></app-sidebar>
  <div class="main-content">
    <div class="page-content">
      <div class="container-fluid">
        <!-- Main Content -->
        @if(loading) {
        <app-main-loader></app-main-loader>

        } @else { @if(page == false){
        <app-main-loader></app-main-loader>
        }
        <router-outlet></router-outlet>
        }
      </div>
    </div>
    <!-- Footer -->
    <app-footer></app-footer>
  </div>
</div>
<!-- Right Side Bar
<app-rightsidebar
  (settingsButtonClicked)="onSettingsButtonClicked()"
></app-rightsidebar> -->

// We will use this sharedService to share the user Info to the to required components
import { Injectable } from "@angular/core";
import { currUser } from "../models/userModels";
import { BehaviorSubject, Subject } from "rxjs";
import { IComponent } from "../utils/Icomponents";
import { ModulesAndComponents } from "../utils/IModulesAndComponents";
import { IsideMenu } from "../utils/sidemenu.interface";
export interface Imodules {
  moduleId: string;
  moduleLink: string;
}
interface folder {
  isopened: boolean;
  folderId: string;
}
@Injectable({
  providedIn: "root",
})
export class SharedService {
  // create variable to be shared
  user!: currUser;

  //for use only when resetting password
  // this variable will contain the reset token for resetting password
  private sharedVariableSubject = new BehaviorSubject<string>(""); // Initial value
  resetToken$ = this.sharedVariableSubject.asObservable();
  updateResetToken(newValue: string) {
    this.sharedVariableSubject.next(newValue);
  }

  /*****UPDATE SIDEBAR*******
   * LOGIC
   * We will use an object to determine whether to show the static side menu or not
   * this object will be shared throughout the modules and cause the change
   * There will be two fields in the object namely showStaticSideMenu and updateDynamicSideMenu
   * The showStaticSideMenu will determine whether to show the static side menu or not
   * The updateDynamicSideMenu will determine whether update the dynamic side menu when a new module is selected
   */
  //at default, show static side menu
  private sideMenuSubject = new BehaviorSubject<IsideMenu>({} as IsideMenu);
  sideMenu$ = this.sideMenuSubject.asObservable();
  updateSideMenu(value: IsideMenu) {
    this.sideMenuSubject.next(value);
  }

  /***Send true or false to the sidebar to toggle the preloader*/
  private toggleSubject = new Subject<boolean>();
  toggle$ = this.toggleSubject.asObservable();
  showTrue() {
    this.toggleSubject.next(true);
  }
  showFalse() {
    this.toggleSubject.next(false);
  }

  //this is to trigger a button click in a different component for a retry
  private retryButtonClick = new Subject<void>();
  retryButtonClick$ = this.retryButtonClick.asObservable();
  emitRetryButtonClick() {
    this.retryButtonClick.next();
  }

  /***************Trigger for role assignment ****************
   * Send a trigger to the role assignment from the accordion whenver a checkbox or ngselect is selected
   */
  private triggerRoleAssignment = new Subject<any>();

  triggerRoleAssignment$ = this.triggerRoleAssignment.asObservable();

  updateTriggerRoleAssignment(value: any) {
    this.triggerRoleAssignment.next(value);
  }

  /*************** HIDE AND SHOW THE SIDE BAR IN THE FILE MANAGEMENT PATH *******
   * LOGIC
   * We will use a boolean variable to determine whether to hide or show the sidebar
   * NEW: We will also attached the selected folder's id
   */
  private hideSidebar = new BehaviorSubject<folder>({
    isopened: false,
    folderId: "",
  });
  hideSidebar$ = this.hideSidebar.asObservable();
  toggleHideSidebarAndSetFolderId(folderId: string, isOpened: boolean) {
    this.hideSidebar.next({
      isopened: isOpened,
      folderId: folderId,
    });
  }
  setToFalse() {
    this.hideSidebar.next({ isopened: false, folderId: "" });
  }

  /************ MANAGE DROPDOWN STATE *************/
  private activeDropdown: HTMLElement | null = null;
  setActiveDropdown(dropdown: HTMLElement) {
    if (this.activeDropdown && this.activeDropdown !== dropdown) {
      this.activeDropdown.style.display = "none";
    }
    this.activeDropdown = dropdown;
  }
  clearActiveDropdown() {
    this.activeDropdown = null;
  }

  /************ MANAGE UPLAOAD PROGRESS *************/
  private uploadState = new BehaviorSubject<File | null>(null);
  uploadState$ = this.uploadState.asObservable();
  updateUploadState(value: File) {
    this.uploadState.next(value);
  }

  /************ SendComponentId *************/
  private componentIdSubject = new BehaviorSubject<string>("");
  componentId$ = this.componentIdSubject.asObservable();
  updateComponentId(value: string) {
    this.componentIdSubject.next(value);
  }

  /************ Send Upload Progress *************/
  private uploadProgressSubject = new Subject<number>();
  uploadProgress$ = this.uploadProgressSubject.asObservable();

  updateUploadProgress(progress: number) {
    this.uploadProgressSubject.next(progress);
  }

  /************* Save Module Ids and route links ************
   * LOGIC
   * When the user refereshes the page, the top page will fetch for all modules that a user has
   * We will bundle this response by storing it's id and route link in an array of objects
   */
  private modulesSubject = new BehaviorSubject<Imodules[]>([]);
  modules$ = this.modulesSubject.asObservable();
  updateModules(value: Imodules[]) {
    this.modulesSubject.next(value);
  }

  /************* Save selected Module Id and route link ***********
   * LOGIC
   * save selected module id and route link
   */
  private selectedModuleSubject = new BehaviorSubject<Imodules>({} as Imodules);
  selectedModule$ = this.selectedModuleSubject.asObservable();
  updateSelectedModule(value: Imodules) {
    this.selectedModuleSubject.next(value);
  }

  /** TRIGGER FOR COMPONENT TO LOAD
   *  This service will trigger a componnet to load only when
   * the sidebar is done fetching data from backend.
   * In the mean time it will load the loading page
   */
  private tiriggerForPageLoadSubject = new BehaviorSubject<boolean>(false);
  triggerForPageLoad$ = this.tiriggerForPageLoadSubject.asObservable();
  updateTriggerForPageLoad(value: boolean) {
    this.tiriggerForPageLoadSubject.next(value);
  }
}

import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { en_US, NzI18nService } from "ng-zorro-antd/i18n";

@Injectable({ providedIn: "root" })
export class LanguageService {
  // public languages: string[] = ["en", "es", "de", "it", "ru"];
  // constructor(
  //   public translate: TranslateService,
  //   private cookieService: CookieService,
  //   private i18n: NzI18nService
  // ) {
  //   let browserLang: any;
  //   /***
  //    * cookie Language Get
  //    */
  //   this.translate.addLangs(this.languages);
  //   if (this.cookieService.check("lang")) {
  //     browserLang = this.cookieService.get("lang");
  //   } else {
  //     browserLang = translate.getBrowserLang();
  //   }
  //   translate.use(browserLang.match(/en|es|de|it|ru/) ? browserLang : "en");
  // }
  // /***
  //  * Cookie Language set
  //  */
  // public setLanguage(lang: any) {
  //   this.translate.use(lang);
  //   this.cookieService.set("lang", lang);
  // }
  // public switchLanguage() {
  //   this.i18n.setLocale(en_US);
  // }
}

/**Authentication Services
 * We will implement all authentication services here
 * LOGIN, LOGOUT, REGISTER-MEMBER, REGISTER-GUEST  an others as they come
 * On successful login the returned user is stored in browser local storage
 * to keep the user logged in between page refreshes and browser sessions.
 */
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject, throwError, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { backendService } from "./backendService.service";

//interfaces
import { MemberForm } from "../utils/MemberInterface";
import { GuestMember } from "../utils/IGuestMember";
import { OTP } from "../utils/Iotp";
import { currUser } from "../models/userModels";
// API
import { backendAPI } from "src/environments/environment";
import { Router } from "@angular/router";
import { NewPassword } from "../utils/IpasswordCreat";
import { PhoneNumber } from "../utils/IphoneNumber";
import { PasswordReset } from "../utils/IresetPassword";
import { ModulesAndComponents } from "../utils/IModulesAndComponents";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private registeredUserSubject!: BehaviorSubject<currUser | null>;
  public user!: Observable<currUser | null>;
  usersRolesAndPermissions: ModulesAndComponents = {
    Modules: [],
    Components: [],
    SemiComponents: [],
    MiniComponents: [],
    NanoComponents: [],
    Permissions: [],
  }; //will contain all the user's roles and permissions

  constructor(
    private http: HttpClient,
    private router: Router,
    private backendService: backendService
  ) {
    this.registeredUserSubject = new BehaviorSubject(
      JSON.parse(localStorage.getItem("currUser")!)
    );
    this.user = this.registeredUserSubject.asObservable();
  }

  public get UserValue() {
    // console.log(this.registeredUserSubject.value)
    return this.registeredUserSubject.value;
  }

  /** LOGIN
   * When a guest is loging in, the user needs his or her phone number and password
   * @param phoneNumber - The phone number of the guest
   * @param password - The password of the guest
   */

  login(member: string) {
    return this.http
      .post<any>(`${backendAPI.authAPI}/auth/login`, member, httpOptions)
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("currUser", JSON.stringify(user));
          this.registeredUserSubject.next(user);
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem("currUser");
    this.registeredUserSubject.next(null);
    // if(this.UserValue?.isActive)
    this.router.navigate(["/auth/login"]);
  }

  //register a new member account
  /**
   *  @param member contains the needed format and data for a successful registration
   */
  registerMember(member: MemberForm) {
    return this.http
      .post(`${backendAPI.authAPI}/auth/register-member`, member)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /** REGISTER A NEW GUEST ACCOUNT
   *
   *  @param member contains the needed format and data for a successful registration
   *
   */
  registerGuestMember(member: GuestMember): Observable<any> {
    return this.http.post(`${backendAPI.authAPI}/auth/register-guest`, member);
  }

  /** REQUEST FOR OTP
   * @param token string
   */
  requestOTP(token: string) {
    return this.http.post(`${backendAPI.authAPI}/accounts/request-otp`, token);
  }

  /*******REQUEST FOR A PASSWORD RESET ********
   * @param phoneNumber - user's phone number to send reset token to
   */
  requestPasswordReset(phoneNumber: PhoneNumber) {
    return this.http.post(
      `${backendAPI.authAPI}/accounts/request-password-reset`,
      phoneNumber
    );
  }

  /***** PASSWORD RESET ******
   * @param newPassword - user's new password
   */
  resetPassword(newPassword: PasswordReset) {
    return this.http.post(
      `${backendAPI.authAPI}/accounts/password-reset`,
      newPassword
    );
  }

  /**SEND OTP BACK TO API
   * @param otp - the code to send
   * @param token - for authentication
   */

  sendOTP(otp: OTP) {
    return this.http.post(`${backendAPI.authAPI}/account/verify-otp`, otp);
  }

  /**RESET PASSWORD FRESH
   * @param password - the new password
   */
  createPassword(password: NewPassword) {
    return this.http.post(
      `${backendAPI.authAPI}/accounts/password-reset-fresh`,
      password
    );
  }

  // /** THE USER'S DATA DOES NOT CONTAIN THE ROLES AND THE PERMISSION THAT THE USER HAS SO WE WILL CREATE
  //  * A FUNCTION THAT FETCHES THE ROLES AND PERMISSIONS A PERSON HAS, IN THIS CASE WE WILL FETCH ALL THE
  //  * MODULES, COMPONENTS, SEMICOMPONENTS, MINICOMPONENTS AND NANOCOMPONENTS THAT BELONGS TO A USER.
  //  *
  //  * WE WILL THEN KEEP THEM IN AN ARRAY THAT THE PAGES ROUTES WILL CHECK TO SEE IF THE PERSON CAN
  //  * ACCESS THE PAGE
  //  */

  // getAllRolesAndPermissions() {
  //   //use user's id to fetch all the roles and permissions

  //   //modules
  //   this.backendService
  //     .getModulesForMember(this.UserValue?.id)
  //     .subscribe((modules) => {
  //       this.usersRolesAndPermissions.Modules = modules.map(
  //         (module) => module.moduleLink
  //       );

  //       //components
  //       this.backendService
  //         .getComponentForMember(this.UserValue?.id)
  //         .subscribe((data) => {
  //           this.usersRolesAndPermissions.Components = data.map(
  //             (a) => a.componentLink
  //           );

  //           //semiComponents
  //           this.backendService
  //             .getSemiComponentForMember(this.UserValue?.id)
  //             .subscribe((semiComponents) => {
  //               this.usersRolesAndPermissions.SemiComponents =
  //                 semiComponents.map(
  //                   (semiComponent) => semiComponent.semiComponentLink
  //                 );

  //               //miniComponents
  //               this.backendService
  //                 .getMiniComponentForMember(this.UserValue?.id)
  //                 .subscribe((miniComponents) => {
  //                   this.usersRolesAndPermissions.MiniComponents =
  //                     miniComponents.map(
  //                       (miniComponent) => miniComponent.miniComponentLink
  //                     );
  //                   // console.log(
  //                   //   "user roles and perms",
  //                   //   this.usersRolesAndPermissions
  //                   // );

  //                   let jsonString = JSON.stringify(
  //                     this.usersRolesAndPermissions
  //                   );

  //                   localStorage.removeItem("RolesAndPerms");
  //                   localStorage.setItem(
  //                     "RolesAndPerms",
  //                     JSON.stringify(jsonString)
  //                   );
  //                   //nanoComponents
  //                   // this.backendService
  //                   //   .getnanoComponentForMember(this.UserValue?.id)
  //                   //   .subscribe((nanoComponents) => {
  //                   //     this.usersRolesAndPermissions.NanoComponents =
  //                   //       nanoComponents.map(
  //                   //         (nanoComponent) => nanoComponent.nanoComponentLink
  //                   //       );
  //                   //     });
  //                 });
  //             });
  //         });
  //     });
  // }
}

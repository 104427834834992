import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "truncateWithEllipsis",
  standalone: true,
})
export class TruncateWithEllipsisPipe implements PipeTransform {
  transform(value: string, limit = 15): string {
    if (value.length <= limit) {
      return value;
    }

    return value.substring(0, limit) + "...";
  }
}

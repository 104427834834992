import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { Observable, Subject, catchError, map, merge, of } from "rxjs";
//interfaces
import { AllMemberType, MemberTypes } from "../utils/Imembertype";
// API
import { backendAPI } from "src/environments/environment";

//Interfaces
import { memberListModel } from "../models/memberListModel";
import { NonMembers, ChurchNonMembers } from "../utils/nonMembers";
import { generalFunctions } from "../functions/generalFunctions";
import {
  AttendanceInterface,
  IAttendance,
  MarkedBy,
} from "../utils/IAttendance";
import { ChurchClasses } from "../utils/IclassesInAClass";
import { Iposition } from "../utils/position.interface";
import {
  IAnnouncement,
  IAnnouncementUpdateDetails,
  IEvents,
} from "../utils/announcement.interface";
import { Committee, UpdateCommittee } from "../utils/committee";
import { IClass, IClasses } from "../utils/classes.interface";
import {
  AssetStatus,
  Categories,
  AssetStockAdjustment,
  AssetItem,
  AdjustAssetItem,
} from "../utils/Assets";
import {
  IChurchOrganization,
  IChurchOrganizationCreate,
  IChurchOrganizationMember,
  IChurchOrganizationMemberCreate,
} from "../utils/church-organizations.interface";
import { ISystemOrganization } from "../utils/system-organization.interface";
import { ImodulesInterface } from "../utils/Imodules";
import {
  IComponent,
  IMiniComponents,
  INanoComponents,
  ISemicomponents,
} from "../utils/Icomponents";
import { Member } from "../utils/MemberInterface";
import { CommitteeMember, NewCommitteeMember } from "../utils/committeeMember";
import { Society } from "../utils/societys";
import { IStatus } from "../utils/statusinterface";
import {
  Preacher,
  PreacherModel,
  PreacherStatus,
} from "../utils/preacherstatus.interface";
import {
  ChurchOffering,
  ChurchService,
  OfferingTypes,
} from "../utils/church-offering";
import {
  IFilesInFolder,
  IFolder,
  INewFolder,
} from "../utils/filesAndFolders.interface";
import { event } from "jquery";
import { IEvent } from "../utils/societyEvent.interface";
import { menus } from "../utils/roles.utils";
import { dataTable } from "../utils/dataTable.type";

interface Permissions {
  accountId: string;
  menuComponentId: string;
  associatedId: string;
  permissions: string;
}
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class backendService {
  constructor(private http: HttpClient, private GF: generalFunctions) {}

  get(path: string, params?: any): Observable<any> {
    let httpParams = new HttpParams();
    if (params) {
      httpParams = new HttpParams({ fromObject: params });
    }

    return this.http.get(`${backendAPI.memberAPI}/${path}`, {
      params: httpParams.keys().length ? httpParams : undefined,
    }) as Observable<any>;
  }

  post<T>(path: string, payload: T, params?: any): Observable<any> {
    let httpParams = new HttpParams();
    if (params) {
      httpParams = new HttpParams({ fromObject: params });
    }

    return this.http.post(`${backendAPI.memberAPI}/${path}`, payload, {
      params: httpParams.keys().length ? httpParams : undefined,
    }) as Observable<any>;
  }

  // Get church Name
  getChurchName(churchCode: string) {
    return this.http
      .get<Society>(`${backendAPI.memberAPI}/societies/society/${churchCode}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**GET CLASSES BELONGIN GOT A CHURCH
   * @param churchCode - The church's code
   * @returns A list of classes that belong to the specified church
   */
  getChurchClasses(churchCode: string) {
    return this.http
      .get<{ data: ChurchClasses[] }>(
        `${backendAPI.memberAPI}/church-classes/society/${churchCode}`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /******GET MEMBER TYPES ******/
  getMemberType() {
    return this.http
      .get<{ data: MemberTypes[] }>(`${backendAPI.memberAPI}/member-types`)
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /******************************* MEMBERS SERVICES************************************* */
  /**Get All MEMBER REQUESTS
   * This will return all ACCOUNTD that are not activated
   *  accounts are all accounts who are are !isActive, firstTimeLogin and has accountType of 0 ( for member Account type)
   *  After that, we will reformat the registration date of each returned account for user to view and search easily
   */
  getMemberRequests() {
    return this.http
      .get<memberListModel>(`${backendAPI.memberAPI}/members/by-church`)
      .pipe(
        map((memberRequests) => {
          let member: memberListModel;
          //filter results to get all accounts that are not activated
          memberRequests.data.filter(
            (item) =>
              !item.isActive && item.firstTimeLogin && item.accountType == 0
          );
          member = memberRequests;
          return member;
        })
      );
  }

  getMembers() {
    return this.http
      .get<memberListModel>(`${backendAPI.memberAPI}/members/by-church`)
      .pipe(
        map((members) => {
          return {
            metadata: members.metadata,
            data: members.data.filter((item) => item.isActive),
          };
        })
      );
  }

  getSortAndFilteredMembers(SFP: dataTable) {
    let params = new HttpParams()
      .append("pageNumber", `${SFP.pageNumber}`)
      .append("pageSize", `${SFP.pageSize}`)
      .append("sortBy", `${SFP.sortBy}`)
      .append(`${SFP.filter?.key}`, `${SFP.filter?.value}`);
    return this.http
      .get<memberListModel>(`${backendAPI.memberAPI}/members/by-church`, {
        params,
      })
      .pipe(
        map((members) => {
          return {
            metadata: members.metadata,
            data: members.data.filter((item) => item.isActive),
          };
        })
      );
  }

  /**Get Member History
   * @param {string} memberId Member
   * @returns {object} Member History
   */
  getMemberHistory(memberId: string): Observable<any> {
    return this.http
      .get<any>(
        `${backendAPI.memberAPI}/member-history/by-member/${memberId}`,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**Verify Member
   * @param memberId - Id of the member to be verified
   * no body required
   */
  activateMember(memberId: string) {
    return this.http
      .post(`${backendAPI.memberAPI}/members/activate/${memberId}`, {})
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**
   * ADD NEW NON-MEMBER
   * @param nonmember - body of the member to be added
   */
  addNonMember(nonmember: NonMembers) {
    return this.http
      .post<{ data: ChurchNonMembers }>(
        `${backendAPI.memberAPI}/non-members`,
        nonmember
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**
   * GET SPECIFIC MEMBER
   * @param id - id of the member
   */
  getMember(id: string): Observable<memberListModel> {
    return this.http
      .get<memberListModel>(`${backendAPI.memberAPI}/members/${id}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  /**
   * Get Non-Members
   */
  getNonMembers() {
    return this.http
      .get<{ data: ChurchNonMembers[] }>(
        `${backendAPI.memberAPI}/non-members/by-society `
      )
      .pipe(
        map((nonmembers) => {
          return nonmembers.data;
        })
      );
  }

  /**Edit Non Member
   * @param id the id of the non member
   * @param nonMember body of the non member to be edited
   */
  editNonMember(id: string, nonMember: NonMembers) {
    return this.http
      .put(`${backendAPI.memberAPI}/non-members/${id}`, nonMember, httpOptions)
      .pipe(
        map((response) => {
          return response as ChurchNonMembers;
        })
      );
  }

  /**delete a non member */
  deleteNONmember(id: string) {
    return this.http
      .delete(`${backendAPI.memberAPI}/non-members/${id}`, httpOptions)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /***************************END OF MEMBER SERVICES *********************************/

  /******************************ROLES AND MODULES ***********************************/

  /**GET MODULES BELONGING TO A MEMBER
   *
   * On login we will always make a fetch to this endpoint to get the the modules that
   * have been assigned to a member.
   * @param id - the id of the member
   *
   */
  getModulesForMember(memberId: string | undefined) {
    return this.http
      .get<{ data: ImodulesInterface[] }>(
        `${backendAPI.memberAPI}/accounts-modules/account-id/${memberId}`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /**ADD MODULES TO A USER
   *
   * @param body - the body of the data to be sent
   *
   */

  addModulesToMember(body: string) {
    return this.http
      .post(`${backendAPI.memberAPI}/accounts-modules`, body, httpOptions)
      .pipe(
        map((response) => {
          return response as ImodulesInterface;
        })
      );
  }
  /**DELETE MODULES FROM A USER
   *
   * @param id - id of the module belonging to the user
   *
   */
  removeModulesFromMember(id: string) {
    return this.http
      .delete(`${backendAPI.memberAPI}/accounts-modules/${id}`, httpOptions)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**GET COMPONENT BELONGING TI A USER
   *
   * @param memberId - the id of the member
   *
   */

  getComponentForMember(memberId: string | undefined) {
    return this.http
      .get<{ data: IComponent[] }>(
        `${backendAPI.memberAPI}/accounts-components/account-id/${memberId}`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /**GET COMPONENTS THAT ARE UNDER A CERTAIN MODULE
   *
   * @param moduleId - the id of the module
   * @param memberId - the id of the member
   */
  getComponentsForModule(
    memberId: string | undefined,
    moduleId: string | undefined
  ) {
    return this.http
      .get<{ data: IComponent[] }>(
        `${backendAPI.memberAPI}/accounts-components/by-account-moduleId/${memberId}/${moduleId}`
      )
      .pipe(
        map((response) => {
          let components: IComponent[] = response.data;
          components = response.data.sort(
            (a, b) => a.componentSort - b.componentSort
          );
          return components;
        })
      );
  }
  /**ADD COMPONENTS TO A USER
   *
   * @param body - the body of the data to be sent
   *
   */
  addComponentsToMember(body: string) {
    return this.http
      .post(`${backendAPI.memberAPI}/accounts-components`, body, httpOptions)
      .pipe(
        map((response) => {
          return response as IComponent;
        })
      );
  }
  /**REMOVE A COMPONENT FROM A USER
   *
   * @param body - The body of the data to be sent
   */
  removeComponentFromMember(body: string) {
    return this.http
      .delete(
        `${backendAPI.memberAPI}/accounts-components/${body}`,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**GET SEMI COMPONENTS BELONGING T0 A USER
   *
   * @param memberId - the id of the member
   *
   */
  getSemiComponentForMember(memberId: string | undefined) {
    return this.http
      .get<{ data: ISemicomponents[] }>(
        `${backendAPI.memberAPI}/accounts-semi-components/account-id/${memberId}`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }
  /*****GET SEMI COMPONENTS BELONGING TO A PARTICULAR COMPONENT
   *
   * @param memberId - the id of the member
   * @param componentId - the id of the component
   *
   */
  getSemiComponentsForComponent(
    memberId: string | undefined,
    componentId: string
  ) {
    return this.http
      .get<{ data: ISemicomponents[] }>(
        `${backendAPI.memberAPI}/accounts-semi-components/by-account-id/${memberId}/${componentId}`
      )
      .pipe(
        map((response) => {
          let semiComponents: ISemicomponents[];
          semiComponents = response.data.sort(
            (a, b) => a.semiComponentSort - b.semiComponentSort
          );
          return semiComponents;
        })
      );
  }

  /**ADD nano COMPONENTS TO A USER
   *
   * @param body - the body of the data to be sent
   *
   */
  addSemiComponentsToMember(body: string) {
    return this.http
      .post(
        `${backendAPI.memberAPI}/accounts-semi-components`,
        body,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response as ISemicomponents;
        })
      );
  }

  /**REMOVE A SEMI COMPONENT FROM A USER
   *
   * @param body - The body of the data to be sent
   */
  removeSemiComponentFromMember(body: string) {
    return this.http
      .delete(
        `${backendAPI.memberAPI}/accounts-semi-components/${body}`,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**GET nano COMPONENTS BELONGING T0 A USER
   *
   * @param memberId - the id of the member
   *
   */

  getnanoComponentForMember(memberId: string | undefined) {
    return this.http
      .get<{ data: INanoComponents[] }>(
        `${backendAPI.memberAPI}/accounts-nano-components/account-id/${memberId}`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /*****GET NANO COMPONENTS BELONGING TO A PARTICULAR COMPONENT
   *
   * @param memberId - the id of the member
   * @param semiComponentId - the id of the component
   *
   */
  getnanoComponentsForSemiComponent(
    memberId: string | undefined,
    semiComponentId: string
  ) {
    return this.http
      .get<{ data: INanoComponents[] }>(
        `${backendAPI.memberAPI}/accounts-nano-components/account-id/${memberId}`
      )
      .pipe(
        map((response) => {
          let nanoComponents: INanoComponents[];
          nanoComponents = response.data.filter(
            (item: any) => item.parentId == semiComponentId
          );
          return nanoComponents;
        })
      );
  }

  /**ADD nano COMPONENTS TO A USER
   *
   * @param body - the body of the data to be sent
   *
   */
  addnanoComponentsToMember(body: string) {
    return this.http
      .post(
        `${backendAPI.memberAPI}/accounts-nano-components`,
        body,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response as INanoComponents;
        })
      );
  }
  /**REMOVE A NANO COMPONENT FROM A USER
   *
   * @param body - The body of the data to be sent
   */
  removeNanoComponentFromMember(body: string) {
    return this.http
      .delete(
        `${backendAPI.memberAPI}/accounts-nano-components/${body}`,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  /**GET MINI COMPONENTS BELONGING TI A USER
   *
   * @param memberId - the id of the member
   *
   */

  getMiniComponentForMember(memberId: string | undefined) {
    return this.http
      .get<{ data: IMiniComponents[] }>(
        `${backendAPI.memberAPI}/accounts-mini-components/account-id/${memberId}`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /*****GET mini COMPONENTS BELONGING TO A PARTICULAR COMPONENT
   *
   * @param memberId - the id of the member
   * @param nanocomponentId - the id of the component
   *
   */
  getMiniComponentsForComponent(
    memberId: string | undefined,
    nanocomponentId: string
  ) {
    return this.http
      .get<{ data: IMiniComponents[] }>(
        `${backendAPI.memberAPI}/accounts-mini-components/account-id/${memberId}`
      )
      .pipe(
        map((response) => {
          let miniComponents: IMiniComponents[];
          miniComponents = response.data.filter(
            (item: any) => item.parentId == nanocomponentId
          );
          return miniComponents;
        })
      );
  }
  /**ADD MINI COMPONENTS TO A USER
   *
   * @param body - the body of the data to be sent
   *
   */
  addMiniComponentsToMember(body: string) {
    return this.http
      .post(
        `${backendAPI.memberAPI}/accounts-mini-components`,
        body,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  /**REMOVE A MINI COMPONENT FROM A USER
   *
   * @param body - The body of the data to be sent
   */
  removeMiniComponentFromMember(body: string) {
    return this.http
      .delete(
        `${backendAPI.memberAPI}/accounts-mini-components/${body}`,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  /************************* ATTENDANCE *************************/
  /**
   * Lets get a list of memebers from a particular class
   * @param classId - the class in which the members belong to
   *
   */
  getMembersForClass(classId: string | undefined) {
    return this.http
      .get<{ data: Member[] }>(
        `${backendAPI.memberAPI}/members/by-class/${classId}`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /**SEND MARKED ATTENDANCE
   * @param attendace - the total marked attendance for the week
   */
  sendAttendance(attendace: AttendanceInterface) {
    return this.http
      .post(`${backendAPI.memberAPI}/class-attendance`, attendace, httpOptions)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**GET ID OF WHO MARKED THIS WEEK'S ATTENDANCE
   * @returns the the person who marked this week's attendance.
   */
  getWhoMarkedAttendance() {
    return this.http
      .get<{ data: MarkedBy[] }>(
        `${backendAPI.memberAPI}/class-attendance/weekly`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /**GET DETAILS OF LAST WEEKS MARKED ATTENDACE
   * @param {string} id - The id of the week's attendace attendance
   * @returns {IAttendance} Returns a list of the attendance details
   */
  getAttendance(id: string) {
    return this.http
      .get<IAttendance>(`${backendAPI.memberAPI}/class-attendance/${id}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**GET LIST ALL LIST OF PEOPLE WHO MARKED ATTENDANCE
   * @returns an array of people who have marked an attendace
   */
  getAttendaceBySociety() {
    return this.http
      .get<{ data: MarkedBy[] }>(
        `${backendAPI.memberAPI}/class-attendance/by-society`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /********************************************
          Broadcast / Announcement
  ********************************************/

  /**
   * Gets all announcements, both published and unpublished for all societies
   * @returns IAnnouncement in an array
   */
  getAnnouncements() {
    return this.http
      .get<{ data: IAnnouncement[] }>(`${backendAPI.memberAPI}/announcements`)
      .pipe(map((res) => res.data));
  }

  /**
   * Gets an announcement by its ID
   * @param id The id of the announcement to get
   * @returns IAnnouncement
   */
  getAnnouncement(id: string) {
    return this.http
      .get<{ data: IAnnouncement }>(
        `${backendAPI.memberAPI}/announcements/${id}`
      )
      .pipe(map((res) => res.data));
  }

  /**
   * Gets all published announcements
   * @returns IAnnouncement in an array
   */
  getPublishedAnnouncements() {
    return this.http
      .get<{ data: IAnnouncement[] }>(
        `${backendAPI.memberAPI}/announcements/all-published-active`
      )
      .pipe(map((res) => res.data));
  }

  /**
   * Deletes an announcement
   * @param id The id of the announcement to delete
   * @returns response code
   */
  deleteAnnouncement(id: string) {
    return this.http
      .delete(`${backendAPI.memberAPI}/announcements/${id}`)
      .pipe(map((res) => res));
  }

  /**
   * Creates a new announcement
   * @param data Announcement details to persist
   * @returns response code
   */
  createAnnouncement(data: IAnnouncementUpdateDetails) {
    return this.http
      .post<IAnnouncement>(
        `${backendAPI.memberAPI}/announcements`,
        data,
        httpOptions
      )
      .pipe(map((res) => res));
  }

  /**
   * Update an announcement
   * @param id ID of the announcement to update
   * @param updateInfo Details to update
   * @returns response code
   */
  updateAnnouncement(id: string, updateInfo: IAnnouncementUpdateDetails) {
    return this.http
      .put(
        `${backendAPI.memberAPI}/announcements/${id}`,
        updateInfo,
        httpOptions
      )
      .pipe(map((res) => res));
  }

  /**
   * Publish an announcement
   * @param id ID of the announcement to publish
   * @returns response code
   */
  publishAnnouncement(id: string) {
    return this.http
      .put(
        `${backendAPI.memberAPI}/announcements/publish/${id}`,
        {},
        httpOptions
      )
      .pipe(map((res) => res));
  }

  /**
   * Broadcast an announcement
   * @param id ID of the announcement to broadcast
   * @returns response code
   */
  broadcastAnnouncement(id: string) {
    return this.http
      .put(
        `${backendAPI.memberAPI}/announcements/broadcast/${id}`,
        {},
        httpOptions
      )
      .pipe(map((res) => res));
  }

  /**********************SOCIETY OFFICE COMMITEE************* */

  /**Get Committtees
   * @returns - A list of committees in a user's church
   */
  getCommitttees() {
    return this.http
      .get<{ data: Committee[] }>(
        `${backendAPI.memberAPI}/committees/by-society`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /**Get A Committees Members
   * @param committeeName - Name of the committee for filtering
   * @returns - A list of committee members
   */
  getCommitteesMembers(commiteeName: string) {
    return this.http
      .get<{ data: CommitteeMember[] }>(
        `${backendAPI.memberAPI}/committee-members/by-society`
      )
      .pipe(
        map((response) => {
          let committeeMembers = response.data.filter(
            (member) => member.committee == commiteeName
          );
          return committeeMembers;
        })
      );
  }

  /**Get All Members in a committee
   * @returns - A list of committee members in a church
   */
  getAllCommitteeMembers() {
    return this.http
      .get<{ data: CommitteeMember[] }>(
        `${backendAPI.memberAPI}/committee-members/by-society`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /** Update Commitee Info
   *@param id - commitee id
   *@param committee
   *@returns - Updated Commitee Info
   */
  updateCommitteeInfo(id: string, committee: UpdateCommittee) {
    return this.http
      .put(`${backendAPI.memberAPI}/committees/${id}`, committee, httpOptions)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**Add New Commitee
   * @param committee {Committee} - Committee to be added
   * @returns - Committee response
   */
  addCommittee(committee: UpdateCommittee): Observable<Committee> {
    return this.http
      .post(`${backendAPI.memberAPI}/committees`, committee, httpOptions)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /**Add Committee Member
   * @param {NewCommitteeMember} memberInfo - What member to be added
   */
  addCommitteeMember(memberInfo: NewCommitteeMember) {
    return this.http
      .post(
        `${backendAPI.memberAPI}/committee-members`,
        memberInfo,
        httpOptions
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /**Remove A Committee Member
   * @param committeeMemberId {string} - id of committee member to be removed
   */
  removeCommitteeMember(committeeMemberId: string): Observable<any> {
    return this.http
      .delete(
        `${backendAPI.memberAPI}/committee-members/${committeeMemberId}`,
        httpOptions
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /**Remove A Committee
   * @param committeeId {string} - id of committee
   */
  removeACommittee(committeeId: string): Observable<any> {
    return this.http
      .delete(`${backendAPI.memberAPI}/committees/${committeeId}`, httpOptions)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /*********************POSITIONS ******************/
  /**Get Positions in a church
   * @returns - all positions in the church
   */
  getAllChurchPositions(): Observable<Iposition[]> {
    return this.http
      .get<{ data: Iposition[] }>(`${backendAPI.memberAPI}/positions`)
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /********************************************
          Chuuch Classes
  ********************************************/

  /**Get Classes belonging to a church
   * @param {String} churchCode The code of the church
   * @returns a list of all classes belonging to a church
   */
  getClassesBelongingToAChurch(churchCode: string): Observable<IClasses[]> {
    return this.http
      .get<{ data: IClasses[] }>(
        `${backendAPI.memberAPI}/church-classes/society/${churchCode}`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /**Edit a class
   * @param {IClass} body - The details to be edited
   * @param {string} id - The id of the class to be edited
   * @returns response from the server
   */
  editClass(body: IClass, id: string) {
    return this.http
      .put(`${backendAPI.memberAPI}/church-classes/${id}`, body, httpOptions)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /**Remove A Class
   * @param {string} classId - id of committee
   */
  removeClass(classId: string) {
    return this.http
      .delete(`${backendAPI.memberAPI}/church-classes/${classId}`, httpOptions)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /**Add New Class
   * @param {IClass} newClass - The new class to be added
   */
  addNewClass(newClass: IClass) {
    return this.http
      .post(`${backendAPI.memberAPI}/church-classes`, newClass, httpOptions)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /********************************************
              Chuuch Assets Categories
    ********************************************/

  /**Get All Asset categories Belonging to a society
   *@returns a list of all asset categories
   */
  getAllAssetCaegoriesInASociety(): Observable<Categories[]> {
    return this.http
      .get<{ data: Categories[] }>(
        `${backendAPI.memberAPI}/asset-categories/by-society`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /**Add New Asset Categories
   * @param {Categories} category - The category to add
   */
  addnewCategory(category: Categories) {
    return this.http
      .post(`${backendAPI.memberAPI}/asset-categories`, category, httpOptions)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /**Edit an Asset
   * @param {string} categoryName The name of the category
   * @param {string} id The id of the asset category
   */
  editAnAssetCategory(category: Categories, id: string) {
    return this.http
      .put(
        `${backendAPI.memberAPI}/asset-categories/${id}`,
        category,
        httpOptions
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /**Delete an Asset Category
   * @param {string} id The id of the category to delete
   */
  deleteAssetCategory(id: string) {
    return this.http
      .delete(`${backendAPI.memberAPI}/asset-categories/${id}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /***************************************
              CHURCH ORGANIZATION
  ***************************************/
  getChurchOrganizations() {
    return this.http
      .get<{ data: IChurchOrganization[] }>(
        `${backendAPI.memberAPI}/organizations`,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /**
   *
   * @param payload data to send for organization creation
   */
  addChurchOrganization(payload: IChurchOrganizationCreate) {
    return this.http
      .post(`${backendAPI.memberAPI}/organizations`, payload, httpOptions)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getChurchOrganizationMembers() {
    return this.http
      .get<{ allOrganizationMembers: IChurchOrganizationMember[] }>(
        `${backendAPI.memberAPI}/organization-members`,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response.allOrganizationMembers;
        })
      );
  }

  /**Get Member Status
   * @returns Member Statuses
   */
  getMemberStatus() {
    return this.http
      .get<{ data: IStatus[] }>(`${backendAPI.memberAPI}/statuses`, httpOptions)
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }
  addChurchOrganizationMember(payload: IChurchOrganizationMemberCreate) {
    return this.http
      .post(
        `${backendAPI.memberAPI}/organization-members`,
        payload,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deleteChurchOrganizationMember(id: string) {
    return this.http
      .delete(`${backendAPI.memberAPI}/organization-members/${id}`, httpOptions)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  updateChurchOrganizationMember(
    id: string,
    payload: IChurchOrganizationMemberCreate
  ) {
    return this.http
      .put(
        `${backendAPI.memberAPI}/organization-members/${id}`,
        payload,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /********************************
        SYSTEM ORGANIZATIONS
   *******************************/

  getSystemOrganizations() {
    return this.http
      .get<{ data: ISystemOrganization[] }>(
        `${backendAPI.memberAPI}/system-organizations`,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /********************************************
              Church Assets Status
    ********************************************/

  /**Get All Asset statuses Belonging to a society
   *@returns a list of all asset status
   */
  getAllAssetStatusInASociety(): Observable<AssetStatus[]> {
    return this.http
      .get<{ data: AssetStatus[] }>(`${backendAPI.memberAPI}/asset-status`)
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /**Add New Asset Status
   * @param {AssetStatus} status - The status to add
   */
  addnewStatus(status: AssetStatus) {
    return this.http
      .post(`${backendAPI.memberAPI}/asset-status`, status, httpOptions)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /**Edit an Asset
   * @param {string}  assetName name of the status
   * @param {string} id The id of the asset status
   */
  editAnAssetStatus(assetName: AssetStatus, id: string) {
    return this.http
      .put(`${backendAPI.memberAPI}/asset-status/${id}`, assetName, httpOptions)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  /**Delete an Asset Status
   * @param {string} id The id of the Status to delete
   */
  deleteAssetStatus(id: string) {
    return this.http.delete(`${backendAPI.memberAPI}/asset-status/${id}`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  /********************************
   Asset Stock Adjustment
   *******************************/

  /**ADD NEW ASSET STOCK ADJUSTMENT
   * @param {AssetStockAdjustment} assetStockAdjustment The assetStockAdjustment to add
   */
  addNewStockAdjustment(assetStockAdjustment: AssetStockAdjustment) {
    return this.http
      .post(
        `${backendAPI.memberAPI}/asset-stock-adjustments`,
        assetStockAdjustment,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**EDIT ASSET STOCK ADJUSTMENT
   * @param {string} id The id of assetStockAdjustment to edit
   * @param {AssetStockAdjustment} assetStockAdjustment The assetStockAdjustment to add
   */
  editAssetStockAdjustment(
    assetStockAdjustment: AssetStockAdjustment,
    id: string
  ) {
    return this.http
      .put(
        `${backendAPI.memberAPI}/asset-stock-adjustments/${id}`,
        assetStockAdjustment,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**GET ALL ASSET STOCK ADJUSTMENT
   * @returns {AssetStockAdjustment} A list of all Assets Stock Adjustments
   */
  getAllAssetStockAdjustment(): Observable<AssetStockAdjustment[]> {
    return this.http
      .get<{ data: AssetStockAdjustment[] }>(
        `${backendAPI.memberAPI}/asset-stock-adjustments/by-society`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /**DELETE AN ASSET STOCK ADJUSTMENT
   * @param {string} id The id of the asset to be deleted
   */
  deleteAssetStockAdjustment(id: string) {
    return this.http
      .delete(
        `${backendAPI.memberAPI}/asset-stock-adjustments/${id}`,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**Edit an assest stock addjustment item
   * @param body the request body to be made
   */
  editAssetStockAdhustmentItems(data: AdjustAssetItem) {
    return this.http
      .put(
        `${backendAPI.memberAPI}/asset-stock-adjustment-items`,
        data,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /*********************
        ASSET iTEMS
   *********************/
  /**GET ALL ASSET STOCK ADJUSTMENT
   * @returns {AssetItem} A list of all Assets items
   */
  getAllAssetItems(): Observable<AssetItem[]> {
    return this.http
      .get<{ data: AssetItem[] }>(
        `${backendAPI.memberAPI}/asset-items/by-society`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /**EDIT ASSET STOCK ADJUSTMENT
   * @param {string} id The id of assetItem to edit
   * @param {AssetItem} assetItem The assetStockAdjustment to add
   */
  editAssetItem(assetItem: AssetItem, id: string) {
    return this.http
      .put(`${backendAPI.memberAPI}/asset-items/${id}`, assetItem, httpOptions)
      .pipe(
        map((response) => {
          return response as AssetItem;
        })
      );
  }

  /**DELETE AN ASSET ITEM
   * @param {string} id The id of the asset to be deleted
   */
  deleteAssetItem(id: string) {
    return this.http
      .delete(`${backendAPI.memberAPI}/asset-items/${id}`, httpOptions)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**ADD ASSET ITEM
   * @param {AssetItem} addedItem the item to add
   */
  addAssetItem(addedItem: AssetItem): Observable<AssetItem> {
    return this.http
      .post<AssetItem>(
        `${backendAPI.memberAPI}/asset-items`,
        addedItem,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /*********************
      LOCAL PREACHERS  
   ********************/

  /**Get Local Preacher status
   * @returns {PreacherStatus} all precher statuses
   */
  getPreacherStatus(): Observable<PreacherStatus[]> {
    return this.http
      .get<{ data: PreacherStatus[] }>(
        `${backendAPI.memberAPI}/local-preacher-status`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }
  /**Get All Local Preachers
   * @returns {Preacher} all precher statuses
   */
  getAllPreacher(): Observable<Preacher[]> {
    return this.http
      .get<{ data: Preacher[] }>(`${backendAPI.memberAPI}/local-preachers`)
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /**ADD ASSET ITEM
   * @param {PreacherModel} newPreacher the item to add
   */
  addNewLocalPreacher(newPreacher: PreacherModel): Observable<PreacherModel> {
    return this.http
      .post<PreacherModel>(
        `${backendAPI.memberAPI}/local-preachers`,
        newPreacher,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**CHANGE PREACHER STATUS
   * @param {status:string} preacherStatus the new status to be assigned
   * @param {string} id the id of the preacher whose status is to updatted
   */
  changePreacherstatus(newStatus: { status: string }, id: string) {
    return this.http
      .put(
        `${backendAPI.memberAPI}/local-preachers/change-status/${id}`,
        newStatus,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  /**CHANGE PREACHER STATUS
   * @param {PreacherModel} preacher the new status to be assigned
   * @param {string} id the id of the preacher whose status is to updatted
   */
  editPreacherInfo(preacher: PreacherModel, id: string) {
    return this.http
      .put(
        `${backendAPI.memberAPI}/local-preachers/${id}`,
        preacher,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  /**DELETE A PREACHER
   * @param {string} id The id of the asset to be deleted
   */
  deletePreacher(id: string) {
    return this.http
      .delete(`${backendAPI.memberAPI}/local-preachers/${id}`, httpOptions)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  /********************************
   *      Accounts Permissions    *
   ********************************/

  /***Get all permissions belonging to this account
   * @param {string} accountId The id of the account
   * @param {string} lastLayerId The id of the last layer
   * @returns A list of permissions belonging to this account
   */
  getPermissions(accountId: string, lastLayerId: string) {
    return this.http
      .get(
        `${backendAPI.memberAPI}/accounts-permissions/account-id/${accountId}/last-layer-id/${lastLayerId}`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getAccountMenus(accountId: string) {
    return this.http
      .get<{ data: menus[] }>(
        `${backendAPI.memberAPI}/accounts-menu-component/account-id/${accountId}`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }
  getAccountMenusByComponent(lastLayerId: string, accountId: string) {
    return this.http
      .get<{ data: menus[] }>(
        `${backendAPI.memberAPI}/accounts-menu-components/by-account-associatedId/${lastLayerId}/${accountId}`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /**Add Permission To this Account
   * @param {permission} permission - Permission to add to this account
   */
  addPermissionsToMember(permisions: Permissions) {
    return this.http
      .post(
        `${backendAPI.memberAPI}/accounts-menu-components`,
        permisions,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  /********************************
   *      Church Finance    *
   ********************************/

  /***Get all church offerings
   * @returns A list of all church offerings
   */
  getChurchOfferings() {
    return this.http
      .get<{ data: ChurchOffering[] }>(
        `${backendAPI.memberAPI}/society-offering`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /*** Get all church services
   * @returns A list of all church Services
   */
  getChurchServices() {
    return this.http
      .get<{ data: ChurchService[] }>(
        `${backendAPI.memberAPI}/church-services/by-society`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /***Get all Offering Types
   * @returns A list of all Offering Types
   */
  getOfferingTypes() {
    return this.http
      .get<{ data: OfferingTypes[] }>(
        `${backendAPI.memberAPI}/all-offering-types`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /*** Edit an offering type
   * @param id - The id of the offering type to edit
   * @param offeringType - The updated offering type object
   */
  editOfferingType(id: string, offeringType: ChurchOffering) {
    return this.http
      .put<{ data: ChurchOffering }>(
        `${backendAPI.memberAPI}/society-offering/${id}`,
        offeringType
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /** Add a new society offerng
   * @param offerng - the offering to be added
   */
  addSoocietyOffering(offering: ChurchOffering) {
    return this.http
      .post(`${backendAPI.memberAPI}/society-offering`, offering, httpOptions)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /***********************
   *    File Manager     *
   * ********************/

  /**Get All Folders
   * @returns a list of all folders
   */

  getallFolders() {
    return this.http
      .get<{ data: IFolder[] }>(`${backendAPI.memberAPI}/folders`)
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /**Rename a Folder
   * @returns the newly renamed folder name
   * @param folderItem - contains the new folder name and Id
   */
  renameFolder(folderItem: IFolder) {
    return this.http
      .put<{ data: IFolder }>(
        `${backendAPI.memberAPI}/folders/rename/${folderItem.id}`,
        folderItem,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**Delete a Folder
   * @param folderId- the id of the folder to be deleted
   */
  deleteFolder(id: string) {
    return this.http
      .delete(`${backendAPI.memberAPI}/folders/${id}`, httpOptions)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**Add new Folder
   * @param folderItem- the new folder to be added
   */
  addNewFolder(folderItem: INewFolder) {
    return this.http
      .post<{ data: IFolder }>(
        `${backendAPI.memberAPI}/folders?title=${folderItem.title}`,
        folderItem,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /**Get Files in a Folder
   * @param folderId- the id of the folder
   * @returns list of files in the folder
   */
  getFilesInFolder(folderId: string) {
    return this.http
      .get<IFilesInFolder>(
        `${backendAPI.memberAPI}/files/by-folder/${folderId}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  /**Upload files to a folder
   * @param files - the files to be added to a folder
   */
  uploadFiles(files: FormData, folderId: string): Observable<HttpEvent<any>> {
    const url = `${backendAPI.memberAPI}/files/upload`;
    const uploadProgress = new Subject<number>();
    const uploadResponse = new Subject<any>();

    this.http
      .post(url, files, {
        reportProgress: true,
        observe: "events",
      })
      .subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            uploadProgress.next(
              Math.round((event.loaded / event.total!) * 100)
            );
          } else if (event.type === HttpEventType.Response) {
            uploadResponse.next(event.body);
          }
        },
        (error) => {
          uploadResponse.error(error);
        }
      );

    return merge(uploadProgress, uploadResponse);
  }

  /*****************************
   *       Society Events      *
   *****************************/

  // Get all society Events
  getSocietyEvents() {
    return this.http
      .get<{ data: IEvent[] }>(
        `${backendAPI.memberAPI}/society-events/by-society`
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /**Add new Event
   * @param {IEvents} event - the new event to be added
   * @returns the newly added event
   */
  addNewEvent(event: IEvents) {
    return this.http
      .post<{ data: IEvents[] }>(
        `${backendAPI.memberAPI}/society-events`,
        event,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response.data;
        })
      );
  }

  /**Edit an Event
   * @param {IEvents} event - the event to be edited
   * @returns the edited event
   */
  editEvent(event: IEvents, id: string) {
    return this.http
      .put<IEvents>(
        `${backendAPI.memberAPI}/society-events/${id}`,
        event,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  publishEvent(id: string) {
    return this.http
      .put<IEvent>(
        `${backendAPI.memberAPI}/society-events/publish-event/${id}`,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  broadcastEvent(id: string) {
    return this.http
      .put<IEvent>(
        `${backendAPI.memberAPI}/society-events/broadcast-event/${id}`,
        httpOptions
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /***********************
   * @param {string} eventId - the id of the event to be deleted
   * *********************/
  deleteEvent(eventId: string) {
    return this.http
      .delete(`${backendAPI.memberAPI}/society-events/${eventId}`, httpOptions)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}

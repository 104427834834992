import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbDropdownModule, NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { SimplebarAngularModule } from "simplebar-angular";
import { LanguageService } from "../core/services/language.service";
import { TranslateModule } from "@ngx-translate/core";
import { NgbCollapseModule } from "@ng-bootstrap/ng-bootstrap";
// Feather Icon
import { FeatherModule } from "angular-feather";
import { allIcons } from "angular-feather/icons";

// Component pages
import { LayoutComponent } from "./layout.component";
import { VerticalComponent } from "./vertical/vertical.component";
import { TopbarComponent } from "./topbar/topbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { FooterComponent } from "./footer/footer.component";
// import { RightsidebarComponent } from "./rightsidebar/rightsidebar.component";
// import { HorizontalComponent } from "./horizontal/horizontal.component";
// import { HorizontalTopbarComponent } from "./horizontal-topbar/horizontal-topbar.component";
// import { TwoColumnComponent } from "./two-column/two-column.component";
// import { TwoColumnSidebarComponent } from "./two-column-sidebar/two-column-sidebar.component";

// Load Icons
import { defineElement } from "@lordicon/element";
import lottie from "lottie-web";
import { SharedModule } from "../shared/shared.module";
import { RoundPreloaderComponent } from "../shared/round-preloader/round-preloader.component";
import { TruncateWithEllipsisPipe } from "../core/pipes/truncate.pipe";
import { MainLoaderComponent } from "../shared/main-loader/main-loader.component";
import { AvvvatarsComponent } from "@ngxpert/avvvatars";

@NgModule({
  declarations: [
    LayoutComponent,
    VerticalComponent,
    TopbarComponent,
    SidebarComponent,
    FooterComponent,

    // RightsidebarComponent,
    // HorizontalComponent,
    // HorizontalTopbarComponent,
    // TwoColumnComponent,
    // TwoColumnSidebarComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbDropdownModule,
    NgbNavModule,
    SimplebarAngularModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgbCollapseModule,
    SharedModule,
    RoundPreloaderComponent,
    TruncateWithEllipsisPipe,
    MainLoaderComponent,
    AvvvatarsComponent,
    FeatherModule.pick(allIcons),
  ],
  providers: [LanguageService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LayoutsModule {
  constructor() {
    defineElement(lottie.loadAnimation);
  }
}

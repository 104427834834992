import { CUSTOM_ELEMENTS_SCHEMA, Component } from "@angular/core";
import { defineElement } from "@lordicon/element";
import lottie from "lottie-web";
@Component({
  selector: "app-main-loader",
  standalone: true,
  imports: [],
  templateUrl: "./main-loader.component.html",
  styleUrl: "./main-loader.component.scss",
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MainLoaderComponent {
  fullText = "";
  bibii = "";
  private bible: string = "Isaiah 40:31";
  private bibleQuote: string =
    "But they that wait upon the Lord shall renew their strength ...";
  constructor() {
    defineElement(lottie.loadAnimation);
  }
  ngOnInit(): void {
    this.typeText();
    this.typeQUotes();
  }

  typeText(): void {
    let currentLength = 0;
    const interval = setInterval(() => {
      if (currentLength < this.bibleQuote.length) {
        this.fullText += this.bibleQuote.charAt(currentLength);
        currentLength++;
      } else {
        clearInterval(interval);
      }
    }, 20); // Customize typing speed as needed
  }

  typeQUotes(): void {
    let currentLength = 0;
    const interval = setInterval(() => {
      if (currentLength < this.bible.length) {
        this.bibii += this.bible.charAt(currentLength);
        currentLength++;
      } else {
        clearInterval(interval);
      }
    }, 30); // Customize typing speed as needed
  }
}

//we will use auth guard to protect the routes from unauthorized access.
/** We will be implimenting the canActivate interface which allows the guard to decide if a route can be activated
 * with the canActivate() method we will be creating.
 * The method will return true if the user is valid and the route will be activated
 * else false if the user is not valid and the route will not be activated
 */

import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";

// Auth Services
import { AuthService } from "../services/authentication.service";
import { SharedService } from "../services/shared-service.service";
import { Observable } from "rxjs";
import { generalFunctions } from "../functions/generalFunctions";

@Injectable({ providedIn: "root" })
export class AdminGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //first check if user guest or member
    const user = this.authService.UserValue;
    if (user?.accountType == 1) {
      if (user?.hasVerifiedOtp) {
        // user is a guest, redirect to dashoboard
        return true;
      }
    } else if (user?.accountType == 0) {
      // user is a member, do the checks
      // if (user?.hasVerifiedOtp && user?.isActive && !user?.firstTimeLogin) {
      if (user?.hasVerifiedOtp && user?.isActive) {
        //user is authorised so we can return true
        return true;
      }
      // else if(user?.hasVerifiedOtp && user?.isActive && user?.firstTimeLogin){
      //     //user hasn't changed password, so redirect to change password.
      //     this.router.navigate(['/auth/login']),  { queryParams: { returnUrl: state.url }};
      //     return false;
      // }
    }
    // user is not logged in so we have to redirect to login page with the return url
    this.router.navigate(["/auth/login"]);
    return false;
  }
}

/********GUARD FOR AUTHENTICATION ROUTES********** */
@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.authService.UserValue;
    //check if user is logged in and active
    if (user?.token) {
      //lets check if user has verified otp
      if (!user.hasVerifiedOtp && user.firstTimeLogin) {
        //sytem sent
        return true;
      } else {
        return this.router.navigate(["/"]);
      }
    }

    return this.router.navigate(["/auth/login"]);
  }
}
/********GUARD FOR  FORGOTPASSWORD ROUTE***********/
@Injectable({ providedIn: "root" })
export class resetGuard implements CanActivate {
  token!: string;
  constructor(private router: Router, private sharedService: SharedService) {
    this.sharedService.resetToken$.subscribe((value: any) => {
      this.token = value;
    });
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //check if reset token exists
    if (this.token != "") {
      return true;
    }
    return this.router.navigate(["/auth/forgot-password"]);
  }
}

/********GUARD FOR PAGES ***********/
@Injectable({ providedIn: "root" })
export class PagesGuard implements CanActivate {
  constructor(
    private router: Router,
    private generalFunctions: generalFunctions,
    private authService: AuthService
  ) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // if (this.authService.UserValue?.token) {
    //   //get requested routes
    //   const requestedRoute = state.url;
    //   const parts = requestedRoute.split("/"); //split into an array and remove the first element
    //   parts.shift();

    //   // Check if the requested route is in the allowedRoutes array
    //   //it will return a boolean
    //   const routesExists = this.generalFunctions.checkRoutes(parts);
    //   if (routesExists == true) {
    //     return true;
    //   } else {
    //     return this.router.navigate(["/"]);
    //   }
    // } else {
    //   return true;
    // }
    //Pages guards are used to protect the routes from people who are not logged in
    if (this.authService.UserValue?.token) {
      //the person is logged in so we can return true
      //but before that, we want to wait till
      return true;
    } else {
      return this.router.navigate(["/auth/login"]);
    }
  }
}

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import {
  NgbToastModule,
  NgbProgressbarModule,
} from "@ng-bootstrap/ng-bootstrap";

import { FlatpickrModule } from "angularx-flatpickr";
import { CountUpModule } from "ngx-countup";
import { NgApexchartsModule } from "ng-apexcharts";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { SimplebarAngularModule } from "simplebar-angular";

// Swiper Slider

import { LightboxModule } from "ngx-lightbox";

// Load Icons
import { defineElement } from "@lordicon/element";
import lottie from "lottie-web";

// Pages Routing
import { PagesRoutingModule } from "./pages-routing.module";
import { SharedModule } from "../shared/shared.module";
import { DashboardComponent } from "./dashboards/dashboard/dashboard.component";
import { DashboardsModule } from "./dashboards/dashboards.module";

// adminPages
// import { FinancialModule } from './financial/financial.module';
// import { FinancialModule } from "./adminPages/financial/financial.module";
import { SocietyOfficeModule } from "./adminPages/society-office/society-office.module";
// import { WebsiteModule } from "./adminPages/website/website.module";
import { SinglePageComponent } from "./adminPages/single-page/single-page.component";
import { EmptyComponent } from "../shared/empty/empty.component";
// import { RolesModuleModule } from "./roles-module/roles-module.module";
// import { AdministrationModule } from "./adminPages/administration/administration.module";
// import { CircuitModule } from "./adminPages/circuit/circuit.module";
// import { DiocesanModule } from "./adminPages/diocesan/diocesan.module";

@NgModule({
  declarations: [DashboardComponent, SinglePageComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgbToastModule,
    FlatpickrModule.forRoot(),
    CountUpModule,
    NgApexchartsModule,
    LeafletModule,
    NgbDropdownModule,
    SimplebarAngularModule,
    PagesRoutingModule,
    SharedModule,
    LightboxModule,
    DashboardsModule,
    EmptyComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PagesModule {
  constructor() {
    defineElement(lottie.loadAnimation);
  }
}

import { Component, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-quantity-changer",
  templateUrl: "./quantity-changer.component.html",
  styleUrls: ["./quantity-changer.component.scss"],
})
export class QuantityChangerComponent {
  @Output() quantity: EventEmitter<any> = new EventEmitter();
  currentValue = 0;

  increase() {
    this.currentValue++;
    this.sendQuantity();
  }

  getnum(value: number) {
    this.currentValue = value;
    this.sendQuantity();
  }
  decrease() {
    if (this.currentValue > 0) {
      this.currentValue--;
      this.sendQuantity();
    }
  }

  sendQuantity() {
    this.quantity.emit(this.currentValue);
  }
}

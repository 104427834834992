<div class="text-center top">
  <h4>No {{ dataName }} found</h4>
  <script src="https://cdn.lordicon.com/lordicon.js"></script>
  <lord-icon
    src="https://cdn.lordicon.com/uecgmesg.json"
    trigger="loop"
    colors="primary:#848484,secondary:#e4e4e4"
    style="width: 150px; height: 150px"
  >
  </lord-icon>
</div>

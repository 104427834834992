import { Component, OnInit } from "@angular/core";

import { AuthService } from "src/app/core/services/authentication.service";
import { Title } from "@angular/platform-browser";
import { SharedService } from "src/app/core/services/shared-service.service";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private title: Title,
    private sharedService: SharedService
  ) {
    this.title.setTitle("Dashboard");
    //remove the moduleId from local storage
    localStorage.removeItem("moduleId");
  }

  ngOnInit(): void {
    // this.authService.getAllRolesAndPermissions();
    this.sharedService.updateSideMenu({
      showStaticSideMenu: true,
      components: [],
      moduleName: null,
      moduleIcon: null,
    });
  }
}
